import * as React from 'react';
import {Avatar, Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, Link, Typography } from '@mui/material';
import { Article } from '../../resources/domain/Article';
import parse from 'html-react-parser'
import { Author } from '../../resources/domain/Author';
import { useEffect } from 'react';
import { ArticleService } from '../../resources/services/ArticleService';
import { Loading } from '../commons/Loading';

export default function SingleArticlePreview (props: {data: Article}) {
    const [author, setAuthor] = React.useState<Author>()
    const [loading, setLoading] = React.useState(true)

    useEffect(() => {
        ArticleService.getAuthor(props.data.author)
            .then(setAuthor)
            .catch((e) => { console.error(e) })
            .finally(() => { setLoading(false) })
    }, [])

    if (loading)
        return <Loading type={'blank'} color={'#2f2f2f'} />

    return (
    <Grid item xs={12} sm={6} md={4}>
        <Link href={"blog/article/"+props.data.slug+"-"+props.data.id} sx={{textDecoration:'none'}}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
                <Box style={{ position: "relative" }}>
                    <CardMedia component='img' image={props.data.image} alt='article-bg'
                               sx={{filter:'brightness(60%)' }}/>
                    <Box style={{position: "absolute", color: "black", top: 10, left: "40%", transform: "translateX(-50%)"}}>
                        <Typography variant='h6' component='h2' color='white'>
                            {props.data.title}
                        </Typography>
                    </Box>
                    <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant='body1'>
                        {props.data.brief + '...' }
                    </Typography>
                    <CardHeader
                        avatar={
                            <Avatar aria-label='author' src={author?.url || 'avatar-default.png' } />
                        }
                        title={author?.name}
                        subheader={props.data.date.toLocaleDateString("en", {
                                            year: "numeric",
                                            day: "2-digit",
                                            month: "short",
                                        })
                                    }
                    />
                    </CardContent>
                </Box>
            </Card>
        </Link>
    </Grid>
    );
}
