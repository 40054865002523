import React from 'react';
import AdminSettings from './AdminSettings';
import ManageAccount from './ManageAccount';

interface ComponentMap {
    [name: string]: React.ComponentType<any>;
}

const componentMap: ComponentMap = {
    ManageAccount,
    AdminSettings
};

interface DynamicComponentProps {
    componentName: keyof ComponentMap;
}

const DynamicAccountComponent: React.FC<DynamicComponentProps> = ({ componentName }) => {
    const ChosenComponent = componentMap[componentName];

    return ChosenComponent ? <ChosenComponent /> : null;
};

export default DynamicAccountComponent;