import * as React from 'react';
import { AboutUsFooter } from '../commons/AboutUsFooter';
import HeaderBlogPage from './HeaderBlogPage';
import MainBlogcontent from './MainBlogContent';

export default function BlogPage() {

    return (
        <>
            <HeaderBlogPage />
            <MainBlogcontent />
            <AboutUsFooter />
        </>
    );
}