import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useState} from "react";
import {UserApi} from "../../resources/services/UserApi";

export default function ChangePass(props :{mail :string }) {
    const [message, setMessage] = useState('')
    const [passValue, setPassValue] = useState('');
    const [pass2Value, setPass2Value] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [token, setToken] = useState('')


    const handlePass = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPassValue(event.target.value);
        if(pass2Value) {
            setError(event.target.value !== pass2Value);
            (error)? setErrorMessage('Passwords do not match'): setErrorMessage('');
        }
    };
    const handleToken = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setToken(event.target.value)
    const checkPass = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPass2Value(event.target.value);
        setError(event.target.value !== passValue);
        (error)? setErrorMessage('Passwords do not match'): setErrorMessage('');
    };


    const handleSubmit = () => {

        const userApi = new UserApi();
        userApi.changeWithCode(props.mail,token,passValue).then(res => {
            setMessage("")
            window.location.href = '/signin'
        }).catch( res => {
            setMessage(res.response.data.message)
        })
    }

    return (

            <Box
                sx={{
                    padding: 5,
                    pt: 0,
                    width:'100%',
                    margin:'0 auto',
                    flexWrap: 'wrap',
                    display:'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>

                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        name='token'
                        label='Reset code'
                        type='token'
                        id='token'
                        onChange={handleToken}
                    />
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        error = {error}
                        name='password'
                        label='Password'
                        type='password'
                        id='password'
                        onChange={handlePass}
                    />
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        error = {error}
                        name='password2'
                        label='Repeat password'
                        type='password'
                        id='password2'
                        onChange={checkPass}
                        helperText={error?errorMessage:''}
                    />
                    <Button
                        onClick={handleSubmit}
                        fullWidth
                        variant='contained'
                        disabled = { !passValue || error }
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Change password
                    </Button>
                    {message}
                </Box>
            </Box>
    );
}
