import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { UserService } from '../../resources/services/UserService';


const theme = createTheme({
    spacing: 4,
});

export default function BodyHome() {
    const logged = UserService.isLogged()
    return (
        <ThemeProvider theme={theme}>
            <Grid container component='main' sx={{ flexGrow: 1}} >
                <Grid item xs={12} sm={7} md={6} component={Paper} elevation={6} square
                        sx={{
                            backgroundImage: 'url(' + process.env.PUBLIC_URL +'/main-bc.svg)',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: '#03000f',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            minHeight: '100%',
                            color:'white',
                            flexWrap: 'wrap',
                            display:'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                            verticalAlign: 'center',
                            padding: 20
                        }}>
                    <Box>
                        <Typography component='h3' variant='h3'>
                            <b>Find specialty product kits curated by experts and gurus</b>
                        </Typography>
                        <Typography component='h6' variant='h6' noWrap>
                            &nbsp;
                        </Typography>
                        <Typography component='h6' variant='subtitle1'>
                            Less time searching for what you think you need for you next big project or hobby, more time 'git'ing it done.
                        </Typography>
                        <Button href= {(logged)? '/profile' : '/signup'} variant='contained' sx={{ color:'white',
                            textTransform: 'none', borderColor:'white',
                            backgroundColor: '#4b30d1',
                            mr: 5,
                            my: 10,
                            ':hover': {
                                bgcolor: 'white',
                                color: '#4b30d1',
                                borderColor:'#4b30d1',
                            },
                        }}>
                            {(logged)? 'Profile' : 'Sign up'}
                        </Button>
                        <Button href='/kits' variant='contained' sx={{ color:'#4b30d1',
                            textTransform: 'none', backgroundColor: 'white',
                            borderColor: '#03000f', my: 5,
                            ':hover': {
                                bgcolor: '#4b30d1',
                                color: 'white',
                                borderColor:'white',
                            },
                        }}>
                            Browse Kits
                        </Button>
                    </Box>

                </Grid>
                <Grid item
                    xs={false}
                    sm={5}
                    md={6}
                    sx={{
                        backgroundImage: 'url(' + process.env.PUBLIC_URL +'/main-img.svg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#dadada',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        minHeight: '100%'
                    }}
                />

            </Grid>
        </ThemeProvider>
    );
}
