import {Button, DialogActions, DialogContent, IconButton, Snackbar, TextField } from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { UserService } from '../../resources/services/UserService';


type ChildProps = {
    sendDataToParent: (data: boolean) => void;
};

export default function ChangePasswordForm(props: {passwordInput: string, sendDataToParent: any} ) {
    const [password, setPassword] = useState('')
    const [passwordRepeated, setPasswordRepeated] = useState('')
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState<string>("");
    const [error, setError] = React.useState(false);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setPassword(e.target.value);
    }
    function handleChangeRepeated(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setPasswordRepeated(event.target.value);
    }
    const handleSubmit = () => {
        const userService = new UserService();
        userService.changePassword(password).then( () => {
            setMessage('Password changed')
            setOpen(true)
        }).catch(() => {
            setMessage( 'Error changing password. Please try again later' )
            setOpen(true)
        })
        sendData()
    }

    useEffect(() => {
        setError( password !== passwordRepeated);
    }, [passwordRepeated]);
    const sendData = () => {
        props.sendDataToParent(false);
    };

    const handleCloseSnackBar = () => {
        setOpen(false);
    };
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackBar}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            <DialogContent >
                <TextField
                    autoFocus
                    margin="dense"
                    label="Your password"
                    fullWidth
                    value={props.passwordInput}
                    type='password'
                    disabled ></TextField>
                <TextField
                    margin="dense"
                    label="New password"
                    fullWidth
                    error={error}
                    onChange={handleChange}
                    type='password'
                ></TextField>
                <TextField
                    type='password'
                    margin="dense"
                    label="Repeat new password"
                    fullWidth
                    error={error}
                    helperText={error ? 'Confirmation password do not match':'' }
                    onChange={handleChangeRepeated}
                    ></TextField>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled = {error  || password !== passwordRepeated}
                    onClick={() => {
                    if(!error){
                        handleSubmit()
                    }
                }} color="primary">
                    Change
                </Button>
            </DialogActions>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                message={message}
                onClose={handleCloseSnackBar}
                action={action}
            />
        </>
    );
}
