import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {createTheme, IconButton, ImageListItemBar, Link, ThemeProvider, useMediaQuery } from '@mui/material';
import {Kit} from "../../resources/domain/Kit";
import {KitProps} from "../../resources/domain/KitProps";

let theme = createTheme()
theme = createTheme(theme , {
    components: {
        MuiImageListItemBar: {
            styleOverrides: {
                title: {
                    fontWeight: 'bold',
                    fontSize: 30,
                    lineHeight: 'normal',
                },
            },
        },
    },
});

function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}
export default function FeaturedKitsTop(props: {data: Array<Kit>}) {
    const featureProps : Array<KitProps> = [
        new KitProps(0,1,3),
        new KitProps(1,1,3)
    ]
    function getFeatureKitProps (kit : Kit): KitProps {
        const kitPropertiesFound = featureProps.filter(itemProps => itemProps.position === kit.featurePosition)
        return kitPropertiesFound.length? kitPropertiesFound[0] : (new KitProps(kit.featurePosition))
    }
    const featuresKits = props.data.filter(kit => {return (kit.featurePosition === 0 || kit.featurePosition === 1 )})

    const mobile = useMediaQuery('(max-width:600px)');
    return (
        <ThemeProvider theme={theme}>
        <Box sx={{ margin: '0 auto', mb:5 }}>
            <Typography variant='h5' sx={{ textAlign: 'left', my: 5 }}>
                <b>New Expert Collections</b>
            </ Typography>
            <ImageList
                variant="quilted"
                cols={mobile ? 1: 2}
                rowHeight= {mobile ? 60 : 120}
                gap={18}

            >
                {featuresKits
                    .sort((a,b) => a.featurePosition - b.featurePosition)
                    .map((item) => (
                        <ImageListItem key={item.featureImage} cols={1} rows={3}
                                       sx = {{cursor: 'pointer'}}
                                       onClick={() => window.location.href = `/kit/${item.slug}-${item.id}`}>
                            <img
                                {...srcset(item.featureImage, 100,
                                    getFeatureKitProps(item).rows,
                                    getFeatureKitProps(item).cols
                                )}
                                alt={item.name}
                                loading="lazy"
                            />
                            <ImageListItemBar
                                sx={{
                                    background:
                                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                        'rgba(0,0,0,0.3) 40%, rgba(0,0,0,0) 100%)',
                                    p: 2
                                }}
                                title={item.name}
                                position= 'top'
                            />
                            <ImageListItemBar
                                sx={{
                                    background: 'rgba(0,0,0,0) 0%',
                                    p: "12px 16px"
                                }}
                                position = 'bottom'
                                actionPosition= "left"
                                actionIcon={
                                    <IconButton
                                        sx={{ background: 'rgba(255, 255, 255, 0.54)',
                                            color: 'black',
                                            borderRadius: 10,
                                            px: 5,
                                            m: 2,
                                            fontSize: 20,
                                            '&:hover': {
                                                background: 'rgba(255, 255, 255, 0.70)',
                                                fontWeight: 'bold',
                                            }
                                        }}
                                        href={`/kit/${item.slug}-${item.id}`}
                                        aria-label={`info about ${item.categoryObj?.name}`}
                                    >
                                        {item.categoryObj?.name}
                                    </IconButton>
                                }
                            />
                        </ImageListItem>
                    ))}
            </ImageList>
        </Box>
        </ThemeProvider>
    );
}


