import * as React from "react";
import Box from "@mui/joy/Box";
import FormLabel from "@mui/joy/FormLabel";
import Radio, { radioClasses } from '@mui/joy/Radio';
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import {FormControl, Grid, Typography } from "@mui/material";
import {useEffect, useState} from 'react';
import { KitService } from "../../resources/services/KitService";
import { Kit } from "../../resources/domain/Kit";
import FeaturedKitsTop from "./FeaturedKitsTop";
import FeaturedKitsBottom from "./FeaturedKitsBottom";
import { SingleKitPreview } from "./SingleKitPreview";
import { styled } from '@mui/material/styles';
import {Category} from "../../resources/domain/Category";
import SearchKits from "../searchKits/SearchKits";
import { Loading } from "../commons/Loading";
import { ErrorPage } from "../commons/ErrorPage";


const Main = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: '#f2f2f2',
    padding: 100,
    paddingBottom: 200,
    [theme.breakpoints.down('sm')]: {
        padding: 20,
        paddingBottom: 100,
    },
}));

export default function MainKitsContent() {

    const [categorySelected, setValue] = useState('default')
    const [categories, setCategories] = useState<Array<Category>>([])
    const [kits, setKits] = useState<Array<Kit>>([])
    const [kitsFeatures, setKitsFeatures] = useState<Array<Kit>>([])
    const [loading, setLoading] = useState(true)


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.value === "default"){
            setValue(event.target.value)
            setKits([])
            return
        }
        const categoryId:number = +event.target.value
        KitService.getKitsByCategory(categoryId)
            .then(setKits)
            .catch((e) => { console.error(e) })
            .finally(() => { setValue(event.target.value)
                                    setLoading(false)
            })
    };
    useEffect(() => {
        KitService.getKitsFeatureds()
            .then(setKitsFeatures)
            .catch((e) => { console.error(e) })
        KitService.getCategories()
            .then(setCategories)
            .catch((e) => { console.error(e) })
            .finally(() => { setLoading(false) })
    }, [])


    return (  loading ? (<Loading color={'#4b30d1'} type={'spin'}/>) :
        <Main >
            <SearchKits />
            <FeaturedKitsTop data={kitsFeatures} />
            <Box sx={{mb: 5, overflow: 'auto'}}>
                <Typography variant='h5' sx={{ textAlign: 'left', my: 3 }}>
                    <b>More Collections</b>
                </ Typography>
                <FormControl>
                <FormLabel
                    sx={{
                        mb: 2,
                        overflow: 'auto',
                        fontWeight: "xl",
                        textTransform: "none",
                        fontSize: "xs",
                        letterSpacing: "0.15rem"
                    }}
                >
                </FormLabel>
                <RadioGroup
                    aria-labelledby="storage-label"
                    value={categorySelected}
                    onChange = {handleChange}
                    sx={{ gap: 2, mb: 2, flexWrap: 'wrap', flexDirection: 'row' }}

                >
                    <Sheet
                        key={"default"}
                        sx={{
                            px: 2,
                            py:1,
                            borderRadius: 20,
                            boxShadow: "sm",
                            textAlign: 'center',
                            m: 1,
                            "--joy-focus-outlineOffset": "4px",
                            "--joy-palette-focusVisible": (theme) =>
                                theme.vars.palette.neutral.outlinedBorder,
                            [`& .${radioClasses.checked}`]: {
                                [`& .${radioClasses.label}`]: {
                                    fontWeight: "lg",
                                    color: "white"
                                },
                                [`& .${radioClasses.action}`]: {
                                    "--variant-borderWidth": "2px",
                                    borderColor: "#1588ec",
                                    backgroundColor: "#1588ec"
                                }
                            },
                            [`& .${radioClasses.action}.${radioClasses.focusVisible}`]: {
                                outlineWidth: "2px"
                            }
                        }}
                    >
                        <Radio
                            label={"All"}
                            overlay
                            disableIcon
                            value={"default"}
                            slotProps = {{
                                label: () => ({
                                    sx: {
                                        fontWeight: "lg",
                                        fontSize: "md",
                                        color: '#1588ec'
                                    }
                                }),
                                action: () => ({
                                    sx: () => ({
                                        borderColor: "#1588ec"
                                    })
                                })
                            }}
                        />
                    </Sheet>
                    {categories.map((value) => (
                        <Sheet
                            key={value.id}
                            sx={{
                                px: 2,
                                py:1,
                                borderRadius: 20,
                                boxShadow: "sm",
                                textAlign: 'center',
                                m: 1,
                                "--joy-focus-outlineOffset": "4px",
                                "--joy-palette-focusVisible": (theme) =>
                                    theme.vars.palette.neutral.outlinedBorder,
                                [`& .${radioClasses.checked}`]: {
                                    [`& .${radioClasses.label}`]: {
                                        fontWeight: "lg",
                                        color: "white"
                                    },
                                    [`& .${radioClasses.action}`]: {
                                        "--variant-borderWidth": "2px",
                                        borderColor: "#1588ec",
                                        backgroundColor: "#1588ec"
                                    }
                                },
                                [`& .${radioClasses.action}.${radioClasses.focusVisible}`]: {
                                    outlineWidth: "2px"
                                }
                            }}
                        > 
                            <Radio
                                label={value.name}
                                overlay
                                disableIcon
                                value={value.id}
                                slotProps = {{
                                    label: () => ({
                                        sx: {
                                            fontWeight: "lg",
                                            fontSize: "md",
                                            color: '#1588ec'
                                        }
                                    }),
                                    action: () => ({
                                        sx: () => ({
                                            borderColor: "#1588ec"
                                        })
                                    })
                                }}
                            />
                        </Sheet>
                    ))}
                </RadioGroup>
                </FormControl>
            </Box>
            <Box >
                { (categorySelected === 'default' ) ?
                    <FeaturedKitsBottom data={kitsFeatures}/>
                    : (kits.length)?
                    <Grid container spacing={4}>
                        {kits.map( (kit)  => <SingleKitPreview data={kit} /> ) }
                    </Grid>
                        : <ErrorPage message={'No kits found'}/>
                }
            </Box>
    </ Main>
    );
}