import * as React from 'react';
import { createTheme,  IconButton, ImageListItem,
    ImageListItemBar,ThemeProvider, } from '@mui/material';
import { Kit } from '../../resources/domain/Kit';


let theme = createTheme()
theme = createTheme(theme , {
    components: {
        MuiImageListItemBar: {
            styleOverrides: {
                title: {
                    fontWeight: 'bold',
                    fontSize: 30,
                    lineHeight: 'normal',
                },
            },
        },
    },
});
function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}
export const FoundKitPreview = (props: {data: Kit}) => (
    <ThemeProvider theme={theme}>
    <ImageListItem key={props.data.id} cols={1} rows={2} >
        <img
            {...srcset(props.data.image, 100, 1, 3)}
            alt={props.data.name}
            loading="lazy"
        />
        <ImageListItemBar
            sx={{
                background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 40%, rgba(0,0,0,0) 100%)',
                p: '8%'
            }}
            title={props.data.name}
            position= 'top'
        />
        <ImageListItemBar
            sx={{
                background: 'rgba(0,0,0,0) 0%',
            }}
            position = 'bottom'
            actionPosition= "left"
            actionIcon={
                <IconButton
                    sx={{ background: 'rgba(255, 255, 255, 0.54)',
                        color: 'black',
                        borderRadius: 10,
                        px: 5,
                        m: 5,
                        fontSize: 20,
                        '&:hover': {
                            background: 'rgba(255, 255, 255, 0.70)',
                            fontWeight: 'bold',
                        }
                    }}
                    href = {"/kit/"+props.data.slug+"-"+props.data.id}
                    aria-label = {`info about ${props.data.category}`}
                >
                    {props.data.categoryObj?.name}
                </IconButton>
            }
        />
    </ImageListItem>
    </ThemeProvider>
)
