import * as React from 'react'
import Typography from '@mui/material/Typography/Typography'
import {AppBar, Toolbar} from '@mui/material';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import SearchAppBar from '../searchKits/SearchAppBar';
import LogInHeader from '../login/LogInHeader';


export const Header = (props: any) => (
    <AppBar
        position='static'
        color='default'
        elevation={0}
        sx={{ backgroundColor: '#4b30d1' }}
    >

        <Toolbar sx={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>

            <Toolbar>
                <Link href = '/' style={{ textDecoration: 'none' }}>
                    <Toolbar >
                        <img src='/logo.svg' alt='logo' className='App-header-img'  />
                        <Typography variant='h6' color='white' sx={{  mx:1,  }}>
                            git<b>Kits</b>
                        </Typography>
                    </Toolbar>
                </Link>
                <SearchAppBar />
            </Toolbar>


            <nav >
                <Link
                    variant='button'
                    color='text.primary'
                    href='/kits'
                    sx={{ my: 1, mx: 1.5, color:'white',
                        textTransform: 'none', textDecorationColor: '#4b30d1'}}
                >
                    Expert Kits
                </Link>
                <Link
                    variant='button'
                    color='text.primary'
                    href='/blog'
                    sx={{ my: 1, mx: 1.5, color:'white',
                        textTransform: 'none', textDecorationColor: '#4b30d1'}}
                >
                    Blog
                </Link>
                <LogInHeader />
            </nav>

        </Toolbar>
    </AppBar>

)
