import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container, Modal } from '@mui/material';
import {UserService} from "../../resources/services/UserService";
import {useEffect, useState} from "react";
const theme = createTheme();

export default function SignUp() {
    const [logged, setLogged] = useState(false)
    const [modal, setModal] = useState(false)
    const [message, setMessage] = useState('')
    const [emailValue, setEmailValue] = useState('');
    const [passValue, setPassValue] = useState('');
    const [pass2Value, setPass2Value] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorEmailMessage, setErrorEmailMessage] = useState('');
    const handleEmail = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setEmailValue(event.target.value);
        if(!event.target.value.includes('@')){
            setErrorEmail(true);
            setErrorEmailMessage('Invalid email');
        } else {
            setErrorEmail(false);
            setErrorEmailMessage('');
        }
    };
    const handlePass = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPassValue(event.target.value);
        if(pass2Value) {
            setError(event.target.value !== pass2Value);
            (error)? setErrorMessage('Passwords do not match'): setErrorMessage('');
        }
    };
    const checkPass = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPass2Value(event.target.value);
        setError(event.target.value !== passValue);
        (error)? setErrorMessage('Passwords do not match'): setErrorMessage('');
    };
    const handleOpen = () => setModal(true)
    const handleClose = () => setModal(false)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const userService = new UserService();
        userService.createUser(emailValue, emailValue, passValue).then( response => {
          setMessage(response.message + ' In 4 seconds you will be redirected to sign in.')
            setTimeout(() => {
                window.location.href = '/signin'
            }, 4000)
          handleOpen()
          UserService.logout()
        }).catch(response => {
            setMessage( (response.response.status ===400)? 'Invalid email address. Please try again'
                                : (response.response.status ===500)?'Email address already in use. Please try again or contact us'
                                    : 'Error creating user. Please try again or contact us' )
            handleOpen()
        })
    }
    useEffect(() => {
        setLogged(UserService.isLogged())
    }
    ,[])

    if(logged) {
        window.location.href = '/home'
    }
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Grid container component='main' sx={{ flexGrow: 1 }}>

                        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{
                            backgroundColor: '#4b30d1',
                            flexWrap: 'wrap',
                            display:'flex',
                            justifyContent: 'left',
                            alignItems: 'center',

                        }}>
                            <Box
                                sx={{
                                    padding: 5,
                                    backgroundColor: 'white',
                                    width:'70%',
                                    margin:'0 auto'
                                }}
                            >
                                <Container sx={{display:'flex', alignItems:'center', justifyContent: 'center' }}>
                                    <img src='/logo-black.svg' alt= 'logo'/>
                                    <Typography component='h3' variant='h4'>
                                        git<b>Kits</b>
                                    </Typography>
                                </Container>
                                <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                    <TextField
                                        margin='normal'
                                        required
                                        fullWidth
                                        error = {errorEmail}
                                        helperText={errorEmail?errorEmailMessage:''}
                                        id='email'
                                        label='Email Address'
                                        name='user'
                                        autoComplete='email'
                                        autoFocus
                                        onChange={handleEmail}
                                    />
                                    <TextField
                                        margin='normal'
                                        required
                                        fullWidth
                                        error = {error}
                                        name='password'
                                        label='Password'
                                        type='password'
                                        id='password'
                                        onChange={handlePass}
                                    />
                                    <TextField
                                        margin='normal'
                                        required
                                        fullWidth
                                        error = {error}
                                        name='password2'
                                        label='Password'
                                        type='password'
                                        id='password2'
                                        onChange={checkPass}
                                        helperText={error?errorMessage:''}
                                    />
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        disabled = {!emailValue || !passValue || error || errorEmail}
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Sign Up
                                    </Button>
                                    <Link href='/signin' variant='body2'>
                                        I have an account
                                    </Link>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={false}
                            sm={4}
                            md={7}
                            sx={{
                                backgroundColor: 'white',
                                flexWrap: 'wrap',
                                display:'flex',
                                justifyContent: 'left',
                                alignItems: 'center',
                            }}
                        >
                            <Box sx={{
                                alignItems:'center', justifyContent: 'center', width: '75%',
                                margin:'auto',
                                [theme.breakpoints.down('sm')]: {
                                    display: 'none',
                                }
                            }}>
                                <Typography component='h4' variant='h4' sx={{width:'85%'}}>
                                    <b>Join a community of people who know how to get things done.</b>
                                </Typography>
                                <Typography component='h6' variant='subtitle1' noWrap>
                                    &nbsp;
                                </Typography>
                                <Typography component='h6' variant='subtitle1'>
                                    Vote for your favorite kits, make reviews and more! Exclusive money making benefits to come for early joiners.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </ThemeProvider>
                <Modal
                    open={modal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{  position: 'absolute' as 'absolute',
                        top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                        width: 400, bgcolor: 'background.paper', outline: 0, display: 'flex',
                        boxShadow: 24, p: 4, justifyContent: 'space-between'}}>
                        <Typography variant="subtitle1" component="h2">
                            {message}
                        </Typography>
                        <Button onClick={handleClose}>Close</Button>
                    </Box>
                </Modal>
            </>
        );
}
