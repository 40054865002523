import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';


export default function Footer() {
    return (
        <Box
            component='footer'
            sx={{
                py: 3,
                px: 3,
                mt: 'auto',
                backgroundColor: '#454545',
                flexWrap: 'wrap', justifyContent: 'space-between',
                display:'flex'
            }}
        >
            <Typography variant='body2' color='white' align='center' >
                {'© '}
                Copyright {new Date().getFullYear()} Yeababy LLC . All rights reserved
                {'.'}
            </Typography>
            <Typography variant='body2' color='white' align='center' >
                <Link color='inherit' href='/policy'>
                    Privacy Policy • Cookie Policy
                </Link>
            </Typography>
        </Box>
    );
}
