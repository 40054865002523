import React, {useEffect, useState} from 'react'
import { Kit } from '../../resources/domain/Kit'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import {Avatar, Button, ButtonGroup, Container, List, ListItem} from '@mui/material';
import {KitService} from "../../resources/services/KitService";
import {Author} from "../../resources/domain/Author";
import {
    EmailShareButton,
    FacebookShareButton,
    RedditShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import { Article } from '../../resources/domain/Article';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

export default function HeaderArticlePage(props: {article: Article}) {
    const [author, setAuthor] = useState<Author>(new Author())
    const shareUrl: string = window.location + ''
    const title: string = props.article.title

    useEffect(() => {
        KitService.getAuthor(3)
            .then(setAuthor)
            .catch((e) => { console.error(e) })
    }, [props.article])

    return (
        <>
        <Paper
        sx={{
            position: 'relative',
                backgroundColor: 'grey.800',
                color: '#fff',
                mb: 4,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: 'url(' + props.article.image + ')',
        }}
        >

        {<img style={{display: 'none'}} src={props.article.image || '/article-bg.jpg'}  alt={props.article.title} />}
            <Box sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,.3)',
                }} />

                    <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        width: '100%',
                        position: 'relative',
                        p: {xs: 3, md: 6},
                        pr: {md: 0},
                    }}
                    >
                    <Typography component='h5' variant='h5' color='white' sx={{px: 15, textAlign: 'center'}}
                                gutterBottom>
                        Product Review
                    </Typography>
                    <Typography component='h1' variant='h3' color='white' sx={{px: 20, textAlign: 'center'}}
                    gutterBottom>
                        <b>{props.article.title}</b>
                    </Typography>
                    <Container sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                    <ButtonGroup variant='contained' aria-label='buy button' sx={{m:2}}>
                        <Button size='small'
                                sx={{ color:'white', borderColor: '#4b30d1',
                                    textTransform: 'none',backgroundColor: '#4b30d1', fontSize:'small', borderRadius: 0,
                                    ':hover': {
                                        backgroundColor: '#361db4',
                                        color: 'white',
                                        borderColor: '#4b30d1',
                                    },
                                }}>Git it</Button>
                        <Button size='small' sx={{ color:'white', borderColor: '#4b30d1',
                            textTransform: 'none', backgroundColor: '#4b30d1', borderRadius: 0,
                            ':hover': {
                                backgroundColor: '#361db4',
                                color: 'white',
                                borderColor: '#4b30d1',
                            },
                        }}>
                            <KeyboardArrowDown fontSize='small' />
                        </Button>
                    </ButtonGroup>
                    </Container>

                    <Container sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Avatar alt={author.name} src={author.url || '/avatar-default.png' }/>
                    <Link variant='subtitle1' sx={{px: 1, color: 'white', textDecoration:'none'}}>
                    {author.name} {props.article.date.toLocaleDateString("en", {
                                    year: "numeric",
                                    day: "2-digit",
                                    month: "short",
                                })}
                    </Link>
                    </Container>
        </Box>
    </Paper>

    <List sx={{ position: 'absolute', top: '15%', right: 0, p: 4 }}>
        <ListItem sx={{p:0}}>
            <FacebookShareButton
                url={shareUrl}
                quote={title}
            >
                <Avatar src='/social/fb-blog-header.svg' />
            </FacebookShareButton>
        </ListItem>
        <ListItem  sx={{p:0}}>
            <TwitterShareButton
                url={shareUrl}
                title={title}
            >
                <Avatar src='/social/tw-blog-header.svg' />
            </TwitterShareButton>
        </ListItem>
        <ListItem  sx={{p:0}}>
            <EmailShareButton
                url={shareUrl}
                subject={title}
                body="body"
            >
                <Avatar src='/social/mail-blog-header.svg' />
            </EmailShareButton>
        </ListItem>
        <ListItem  sx={{p:0}}>
            <RedditShareButton
                url={shareUrl}
                title={title}
            >
                <Avatar src='/social/reddit-blog-header.svg' />
            </RedditShareButton>
        </ListItem>
    </List>
    </>
)
}