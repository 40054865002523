import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MainSettings from './MainSettings';
import { AboutUsFooter } from '../commons/AboutUsFooter';
import { UserService } from '../../resources/services/UserService';


export default function ProfilePage() {
    const [logged, setLogged] = React.useState(UserService.isLogged())

    return (
        <>
            {
                logged ? <>
                    <Box  sx={{flexGrow: 1, backgroundColor: '#f2f2f2', p:5}}>
                        <Typography component='h3' variant='h3' sx={{fontWeight:'bold', pt:5, pb:5}}>
                            My Account
                        </Typography>
                        <MainSettings />
                    </Box>
                    <AboutUsFooter />
                </> : window.location.href = '/home'
            }

        </>
    );
}
