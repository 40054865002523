import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Kit } from '../../resources/domain/Kit';
import { ProductKit } from './ProductKit';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { KitService } from '../../resources/services/KitService';
import HeaderKitPage from './HeaderKitPage';
import {  useParams } from "react-router-dom";
import { Loading } from '../commons/Loading';
import { AboutUsFooter } from '../commons/AboutUsFooter';
import { ErrorPage } from '../commons/ErrorPage';

export default function KitPage() {
    const {kitName}  = useParams() as {kitName: string};
    const [kit, setKit] = useState<Kit>()
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        const urlParts = kitName.split('-')
        const idNumber : number = parseInt(urlParts[urlParts.length - 1])
        KitService.getKit(idNumber)
            .then(setKit)
            .catch((e) => { console.error(e) })
            .finally(() => { setLoading(false) })
    },[kitName])

    if (loading) {
        return <Loading color={'#4b30d1'} type={'spin'} />
    }
    if (!kit) {
        return <ErrorPage message={'Kit not found'} />
    }
    return <Box sx={{ backgroundColor:'#f2f2f2', flexGrow: 1 }} >
            <HeaderKitPage kit={kit} />
            <Container sx={{ py: 8}} >
                <Grid container spacing={4}>
                    {kit.products.filter(product => product.viewer()).map((product)  =>
                        (<ProductKit data={product} key={product.name + ' '+product.id}/>)
                    )}
                </Grid>
            </Container>
            <AboutUsFooter />
           </Box>
}
