import * as React from 'react';
import {Avatar,  Card, CardContent, CardHeader,Grid,
    IconButton, Link, Typography } from '@mui/material';
import { Kit } from '../../resources/domain/Kit';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {useEffect, useState} from "react";
import {KitService} from "../../resources/services/KitService";
import {Author} from "../../resources/domain/Author";


export default function GitterPreview(props: {data: Array<Kit>}) {
    const [authors, setAuthor] = useState<Array<Author>>([])

    useEffect(() => {
        const authorsIds = props.data.map(kit => kit.author)
        const authorsIdsFiltered = authorsIds.filter((item, index) => authorsIds.indexOf(item) === index);
        const authorsPromises = authorsIdsFiltered.map((id) => {
            return KitService.getAuthor(id)
        })
        Promise.all(authorsPromises).then(authors => {
            setAuthor(authors)
        })
    }, [props.data])


    const getAuthors = function (authorsToMap: Array<Author>){
        return authorsToMap.map( (author) => {
            return (
                <Card sx={{maxWidth: '90%'}}>
                    <CardHeader
                        avatar={
                            <Avatar alt={author.name}  src={author.url || 'lavatar-default.png' } />
                        }
                        title={author.name}
                        subheader={author.profession}
                    />
                    <Grid container spacing={0} columns={16} sx={{pl: 2}}>
                        <Grid item xs={1} sm={1} md={1}>
                            <IconButton aria-label="awards">
                                <EmojiEventsIcon/>
                            </IconButton>
                            <IconButton aria-label="linkedin" href={author.url}>
                                <LinkedInIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={14} sm={14} md={14}>
                            <CardContent sx={{pt: 1}}>
                                <Typography variant="body2" color="text.secondary">
                                    {author.description}
                                </Typography>
                                <Link href='' sx={{
                                    textDecoration: 'underline', color: 'text.secondary',
                                    fontSize: 15, fontWeigth: 2,
                                    '&:hover': {textDecorationColor: 'black'}
                                }}> Read author name's posts </Link>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Card>
            )
        })
    }

    return (
        <Grid item xs={12} sm={12} md={12}>
            {getAuthors(authors)}
        </Grid>
    )
}