import {Box, Button, ButtonGroup, Grid, Toolbar } from '@mui/material';
import React, {useEffect, useState } from 'react'
import { Article } from '../../resources/domain/Article';
import { ArticleService } from '../../resources/services/ArticleService';
import { ErrorPage } from '../commons/ErrorPage';
import { Loading } from '../commons/Loading';
import CategoriesBlog from './CategoriesBlog';
import SingleArticlePreview from './SingleArticlePreview';
import SortArticle from './SortArticle';


export default function MainBlogContent() {
    const [searchKey, setSearchKey] = useState('');
    const [sortKey, setSortKey] = useState('desc');
    const [categoryKey, setCategoryKey] = useState('');
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [moreItems, setMoreItems] = useState(false);
    const [articles, setArticles] = useState<Array<Article>>([]);
    const offset = 10;
    const handleSearchKey = (data: string) => {
        setSearchKey(data);
    };
    useEffect( () => {
        ArticleService.getArticleByName(searchKey)
            .then(setArticles)
            .catch((e) => {
                console.error(e)
            })
    },[searchKey])

    const handleSortKey = (data: string) => {
        setSortKey(data);
    };
    const handleCategoryKey = (data: string) => {
        setCategoryKey(data);
    }
    const loadMore = () => {
        setPage(page+1)
    }
    useEffect(() => {
        const categoryNumber = parseInt(categoryKey)
        if(isNaN(categoryNumber)) {
            ArticleService.getArticles(page,offset,sortKey)
                .then(setArticles)
                .catch((e) => {

                    if(e.code === "ERR_BAD_REQUEST"){
                        alert("No more blogs :(")
                        setPage(page -1 )
                        setMoreItems(false)
                    }
                })
                .finally(() => { setLoading(false) })
        }else {
            ArticleService.getArticleByCategory(categoryNumber,page,offset,sortKey)
                .then(setArticles)
                .catch((e) => {

                    if(e.code === "ERR_BAD_REQUEST"){
                        alert("No more blogs :(")
                        setPage(page -1 )
                        setMoreItems(false)
                    }
                })
                .finally(() => { setLoading(false) })
        }

    }, [sortKey,categoryKey,page,searchKey])
    useEffect(() => {
        setLoading(true)
        const categoryNumber = parseInt(categoryKey)
        console.log()
        if(isNaN(categoryNumber)) {
            ArticleService.getArticles(page+1,offset,sortKey)
                .then(()=> {setMoreItems(true)})
                .catch((e) => {
                    console.error(e)
                    console.log(e.code)
                    if(e.code === "ERR_BAD_REQUEST"){
                        setMoreItems(false)
                    }
                })
                .finally(() => { setLoading(false) })
        }else {
            ArticleService.getArticleByCategory(categoryNumber,page+1,offset,sortKey)
                .then(()=> {setMoreItems(true)})
                .catch((e) => {
                    console.error(e)
                    console.log(e.code)
                    if(e.code === "ERR_BAD_REQUEST"){
                        setMoreItems(false)
                    }
                })
                .finally(() => { setLoading(false) })
        }

    }, [articles])

    if(loading)
        return <Loading type={'blank'} color={'#f2f2f2'} />

    return (
        <Box sx={{backgroundColor:'#f2f2f2', width:'100%', px:'10%', pb: 10}}>
            <Toolbar sx={{ justifyContent:'right', margin:'auto', pt:7 }} >
                {/*<SearchArticle searchKeyValue={searchKey} onSearchKeyChange={handleSearchKey} />*/}
                <SortArticle sortKeyValue={sortKey} onSortKeyChange={handleSortKey}/>
            </Toolbar>
            <CategoriesBlog categoryKeyValue={categoryKey} onCategoryKeyChange={handleCategoryKey}/>
            { articles.length ? (
                <>
                    <Grid container spacing={4} sx={{width: '100%', mt:1}}>
                        {articles.map( (article)  =>  <SingleArticlePreview data={article} /> )}
                    </Grid>
                    { moreItems ? ( //articles.length === 1 &&
                        <ButtonGroup variant='outlined' aria-label='load-more' sx={{my:2, display:'flex', justifyContent:'center'}}>
                            <Button size='small'
                                    onClick={loadMore}
                                    sx={{ color:'white', borderColor: '#4b30d1',
                                textTransform: 'none',backgroundColor: '#4b30d1', fontSize:'small', borderRadius: 0,
                                ':hover': {
                                    backgroundColor: '#361db4',
                                    color: 'white',
                                    borderColor: '#4b30d1',
                                },
                            }}>Load more</Button>
                        </ButtonGroup>
                    ) : (<></>)}
                </>
                )
                : <ErrorPage message='No articles to show' />
            }
        </Box>
    );
}