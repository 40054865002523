import * as React from 'react';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator, {
    listItemDecoratorClasses,
} from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemButton from '@mui/joy/ListItemButton';
import Box from '@mui/joy/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import BuildIcon from '@mui/icons-material/Build';
import GroupIcon from '@mui/icons-material/Group';
import Chip from '@mui/joy/Chip';
import { Grid, Typography } from '@mui/material';
import DynamicAccountComponent from './DynamicAccountComponent';
import PaymentsIcon from '@mui/icons-material/Payments';

export default function MainSettings() {
    const [index, setIndex] = React.useState('ManageAccount');
    const [disabledList, setDisabledList] = React.useState(true);

    return (
        <Grid container spacing={{ xs: 2, md: 3 }} sx={{pt:3}} columns={{ xs: 1, sm: 4, md: 12 }}>
            <Grid item xs={1} sm={2} md={4} >
                <List
                    aria-label="Sidebar"
                    sx={{
                        px:3,
                        pt:5,
                        pb:10,
                        backgroundColor: 'white',
                        '--ListItem-paddingLeft': '0px',
                        '--ListItemDecorator-size': '64px',
                        '--ListItemDecorator-color': 'rgba(0, 0, 0, 0.5)',
                        '--ListItem-minHeight': '32px',
                        '--List-nestedInsetStart': '13px',
                        '--joy-palette-primary-plainColor': 'rgb(0, 0, 0)',
                        '--joy-palette-info-100': '#1588ec',
                        '--joy-palette-info-600': '#fff',
                        '--joy-palette-info-plainColor': 'rgba(0, 0, 0, 0.35)',
                        [`& .${listItemDecoratorClasses.root}`]: {
                            justifyContent: 'flex-end',
                            pr: '18px',
                        },
                        '& [role="button"]': {
                            //borderRadius: '0 20px 20px 0',
                        },
                    }}
                >

                    <ListItem nested>
                        <ListItemButton selected={true} sx={{backgroundColor:'#4b30d1', color: 'black'}}>
                            <ListItemDecorator>
                                <SettingsIcon />
                            </ListItemDecorator>
                            General
                        </ListItemButton>
                        <List>
                            <ListItem>
                                <ListItemButton
                                    selected={index === 'ManageAccount'}
                                    variant= 'plain'
                                    onClick={() => setIndex('ManageAccount')}
                                >
                                    <Box sx={{display:(index === 'ManageAccount')?'flex':'none', p:0, m:0, justifyContent: 'center',
                                    }}>
                                        <img src='/Oval.svg' />
                                    </Box>
                                    <ListItemDecorator>
                                        <MailOutlineIcon />
                                    </ListItemDecorator>
                                    <ListItemContent>Manage Account</ListItemContent>
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton
                                    selected={index === 'AdminSettings'}
                                    variant='plain'
                                    onClick={() => setIndex('AdminSettings')}
                                >
                                    <Box sx={{display:(index === 'AdminSettings')?'block':'none', p:0, m:0}}>
                                        <img src='/Oval.svg' />
                                    </Box>
                                    <ListItemDecorator>
                                        <BuildIcon />
                                    </ListItemDecorator>
                                    <ListItemContent>Admin Settings</ListItemContent>
                                </ListItemButton>
                            </ListItem>
                            <ListItem >
                                <ListItemButton disabled>
                                    <ListItemDecorator>
                                        <PaymentsIcon />
                                    </ListItemDecorator>
                                    <ListItemContent> Payout Settings
                                        <Chip
                                            size='sm'
                                            variant='soft'
                                            sx={{ ml: 1 }}
                                        >
                                            soon
                                        </Chip>
                                    </ListItemContent>
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton disabled>
                                    <ListItemDecorator>
                                        <GroupIcon />
                                    </ListItemDecorator>
                                    <ListItemContent>Community Sett
                                        <Chip
                                            size="sm"
                                            variant="soft"
                                            sx={{ ml: 1 }}
                                        >
                                            soon
                                        </Chip>
                                    </ListItemContent>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={1} sm={2} md={8}>
                <Typography variant='body2' >
                    General
                </Typography>
                <DynamicAccountComponent componentName={index} />
            </Grid>
        </Grid>

    );
}
