import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import {  useState } from 'react';
import { IconButton } from '@mui/material';

const Search = styled('div')(({ theme }) => ({
    display: 'flex',
    float: 'left',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.005),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.05),
    },
    marginLeft: 0,
    color:'white',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
        flexGrow:0.7
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        paddingLeft: 1,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '40ch',
            '&:focus': {
                width: '40ch',
            },
        },
    },
}));

export default function SearchAppBar(){
    const [key, setKey] = useState({ value: '' });
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setKey({value: e.target.value});
    }
    return (
        <Search>
            <IconButton href = {'/search/' + key?.value} sx={{
                                                        ml: 1,
                                                        "&.MuiButtonBase-root:hover": {
                                                            bgcolor: "transparent"
                                                        }}}>
                <SearchIcon sx={{color:'white'}} />
            </IconButton>
            <StyledInputBase
                placeholder='Search for kits or products by id or name'
                inputProps={{ 'aria-label': 'search' }}
                value={key?.value}
                onChange={handleChange}
                onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                        window.location.href = '/search/'+key?.value ;
                    }
                }}
            />
        </Search>
    );
}