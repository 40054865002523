import * as React from 'react';
import { AboutUsFooter } from '../commons/AboutUsFooter';
import HeaderKitsPage from './HeaderKitsPage';
import MainKitsContent from './MainKitsContent';


export default function KitsPage() {

    return (
        <>
            <HeaderKitsPage />
            <MainKitsContent />
            <AboutUsFooter />
        </>
    );
}