import {Author} from "../domain/Author";
import {UserApi} from "./UserApi";
import {AuthorType} from "../types/AuthorType";
import { SignupResponseType } from "../types/SignupResponseType";

export class UserService {
    token : string
    userApi : UserApi
    constructor(token:string = "") {
        this.userApi = new UserApi()
        this.token = token;
    }
    async login(user : string, password: string) {
        this.token = await this.userApi.login(user,password)
        localStorage.setItem('session', this.token)
        return this.token
    }

    async createUser(user: string,email : string, password : string): Promise<SignupResponseType>{
        const result = await this.userApi.createUser(user,email,password)
        const message = result === 200 ? 'Account created.'
            : (result ===400)? 'Invalid email address. Please try again.'
                : (result ===500)?'Email address already in use. Please try again or contact us.'
                    : 'Error creating user. Please try again or contact us'
        return {status: result, message : message}
    }

     static isLogged(){
        return localStorage.getItem('userLogin') !== null;
    }
    static getToken(){
        return localStorage.getItem('session')?.replace(/['"]+/g, '')
    }

    static getUserLogged() : Author{
        let userString = localStorage.getItem('userLogin')
        userString = userString ? userString : ""
        const author = new Author()
        if(userString.length) {
            const userType : AuthorType = JSON.parse(userString)
            author.setAuthorByType(userType);
        }
        return author
    }

    static logout(){
        localStorage.removeItem('userLogin')
        localStorage.removeItem('session')
    }

    async me(){
        const authorRequest = await this.userApi.me(this.token)
        const author = new Author();
        author.setAuthor(authorRequest)
        return author
    }

    async changePassword(pass: string){
        const token2 = this.token? this.token :  localStorage.getItem('session')
        this.token = token2 ? token2 : this.token
        const author = UserService.getUserLogged()
        return await this.userApi.changePassword(author.id, this.token, pass)
    }
}
