import { Category } from "./Category";
import { ArticleType } from "../types/ArticleType";

export class Article {
    id: number
    title: string
    slug : string
    category: number
    categoryObj : Category | undefined
    author: number
    url: string
    image : string
    content : string
    date: Date
    brief: string


    constructor(id = -1, title = '',  category = 0, date = '',
                likes = 0, author = 0, url = '',image = '', content = '') {
        this.id = id
        this.title = title
        this.category = category
        this.author = author
        this.url = url
        this.image = image
        this.slug = ''
        this.date = new Date('Sept 24, 22 13:20:18')
        this.content = content
        this.brief ='brief description'
    }

    setArticle (article: ArticleType) {
        this.id = article.id
        this.title = article.title.rendered
        this.category = article.categories[0]
        this.author = article.author
        this.url = article.link
        this.image = article.acf.image
        this.slug = article.slug
        this.date = new Date('Sept 24, 22 13:20:18')
        this.content = article.content.rendered
        this.brief = article.acf.description
    }
}
