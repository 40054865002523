import React from 'react';
import ReactLoading, { LoadingType } from 'react-loading';
import Box from "@mui/joy/Box";

export const Loading = (props: { type: LoadingType, color: string }) => (
    <Box sx={{ backgroundColor:'#f2f2f2', flexGrow: 1, width: '100%',
        display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
        <ReactLoading type={props.type} color={props.color} height={'5%'} width={'5%'} />
    </Box>
);

