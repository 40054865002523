import { ProductType } from '../types/ProductType'

export class Product {
    id: number | undefined
    name: string | null | undefined
    description: string | null | undefined
    cost: number
    rate: number | undefined
    url: string
    image : string

    constructor(name = "p name",id = 0,description = "p description",cost = 0,rate = 0 , url = "",image = "") {
        this.name = name
        this.url = url
        this.cost = cost
        this.rate = rate
        this.description = description
        this.id = id
        this.image = image
    }
    setProduct (product: ProductType) {
        this.id = product.id
        this.name = product.name
        this.description = product.description
        this.cost = parseInt(product.cost+"")
        this.rate = product.rate
        this.url = product.url
    }
    public viewer(){
        return this.url !== "" && this.cost > 0 && this.name !== "p name"
    }
}
