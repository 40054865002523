import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import SignIn from './components/login/SignIn';
import ProfilePage from './components/account/ProfilePage';
import BodyHome from './components/home/BodyHome';
import KitsPage from './components/kits/KitsPage';
import KitPage from './components/kits/KitPage';
import SearchKitPage from './components/searchKits/SearchKitPage';
import { Base } from './components/home/Base';
import { Header } from './components/commons/Header';
import Footer from './components/commons/Footer';
import BlogPage from './components/blog/BlogPage';
import BlogArticlePage from './components/blog/BlogArticlePage';
import {ErrorPage} from './components/commons/ErrorPage';
import PrivacyPolicy from './components/commons/PrivacyPolicy';
import SignUp from './components/login/SignUp';
import ForgotPassword from "./components/login/ForgotPassword";

const router = createBrowserRouter([
    {
        path: '/',
        element: <BodyHome />
    },
    {
        path: '/home',
        element: <BodyHome />
    },
    {
        path: '/index',
        element: <BodyHome />
    },
    {
        path: '/signin',
        element: <SignIn />
    },
    {
        path: '/signup',
        element: <SignUp />
    },
    {
        path: '/forgot',
        element: <ForgotPassword />
    },
    {
        path: '/kits',
        element: <KitsPage />
    },
    {
        path: '/kit/',
        element: <KitsPage />
    },
    {
        path: '/kit/:kitName',
        element: <KitPage />
    },
    {
        path: '/search/:nombre',
        element: <SearchKitPage />
    },
    {
        path: '/profile',
        element: <ProfilePage />
    },
    {
        path: '/blog/',
        element: <BlogPage />
    },
    {
        path: '/blog/article/:articleName',
        element: <BlogArticlePage />
    },
    {
        path: '/policy',
        element: <PrivacyPolicy />
    },
    {
        path: '*',
        element: <ErrorPage message='Nothing here' />
    },

])

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <React.StrictMode>
      <Base>
        <Header />
        <RouterProvider router={router} />
        <Footer />
      </Base>
    </React.StrictMode>
)
