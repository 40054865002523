import {Avatar,  Dialog, DialogActions, DialogTitle, Grid, InputAdornment, Link, Modal, OutlinedInput, Typography } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import GoogleIcon from '@mui/icons-material/Google';
import { Button } from '@mui/joy';
import {useEffect, useState} from 'react';
import ChangePasswordForm from './ChangePasswordForm';
import {UserService} from "../../resources/services/UserService";


const BootstrapInput = styled(OutlinedInput)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,

        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',

        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),

    },
}));
export default function ManageAccount() {
    const [modal, setModal] = useState(false)
    const [option, setOption] = React.useState<string>("");
    const [dataFromChild, setDataFromChild] = useState(true);
    const [email, setEmail] = useState('email@email.com')

    const handleDataFromChild = (data: boolean) => {
        setDataFromChild(data);
        if(!dataFromChild) handleClose()
    };

    useEffect(() => {
        setEmail(UserService.getUserLogged().name)
    }, []);
    const handleOpen = () => setModal(true)
    const handleClose = () => setModal(false)

    return (
        <>
            <Typography component='h3' variant='h5' sx={{fontWeight:'bold', py:1}}>
                Manage Account
            </Typography>
            <FormControl variant="standard" >
                <InputLabel shrink htmlFor="email" >
                    Email
                </InputLabel>
                <BootstrapInput placeholder={email} defaultValue='' disabled
                id="email"
                endAdornment={<InputAdornment position="end">
                    <Link onClick={ ()=>{
                        setOption("email")
                        handleOpen()
                    }  }>
                        Change
                    </Link>
                </InputAdornment>}
                sx={{backgroundColor: 'white',}}/>
            </FormControl>
            <FormControl variant="standard" >
                <InputLabel shrink htmlFor="password" >
                    Password
                </InputLabel>
                <BootstrapInput placeholder='*********' id="password" defaultValue='' disabled
                type='password'
                endAdornment={<InputAdornment position="end">
                    <Link onClick={ ()=>{
                        setOption("password")
                        handleOpen()
                    }  }>
                        Change
                    </Link>
                </InputAdornment>}
                sx={{backgroundColor: 'white',}}/>
            </FormControl>
            <Typography variant='h6' sx={{py:4}}>
                Connected Login Methods
            </Typography>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 4, md: 12 }} sx={{color:'#000'}} >
                <Grid item xs={2} sm={4} md={4} >
                    <Button variant="plain" color='neutral'
                        disabled
                    >
                        <GoogleIcon sx={{ fontSize: '1.7rem', mr:1}}/>
                        Connect with Google
                    </Button>
                </Grid>
                <Grid item xs={2} sm={4} md={4} >
                    <Button variant="plain" color='neutral'
                            disabled
                    >
                        <Avatar src='social/fb.svg' sx={{opacity:'0.3',  mr:1, transform: 'scale(0.8)'}}  />
                        Connect with Facebook
                    </Button>
                </Grid>
                <Grid item xs={2} sm={4} md={4} >
                    <Button variant="plain" color='neutral'
                            disabled
                    >
                        <Avatar src='social/amazon.svg' sx={{opacity:'0.3', mr:1, transform: 'scale(0.8)'}} />
                        Connect with Amazon
                    </Button>
                </Grid>
                <Grid item xs={2} sm={4} md={4} >
                    <Button variant="plain" color='neutral'
                            disabled
                    >
                        <Avatar src='social/apple.svg' sx={{opacity:'0.3', mr:1, transform: 'scale(0.8)'}} />
                        Connect with Apple
                    </Button>
                </Grid>
                <Grid item xs={2} sm={4} md={4} >
                    <Button variant="plain" color='neutral'
                            disabled
                    >
                        <Avatar src='social/mw3.svg'  variant='circular' sx={{opacity:'0.3', mr: 1, transform: 'scale(0.8)'}} />
                        Connect with Metamask Web3
                    </Button>
                </Grid>
            </Grid>
            <Dialog open={modal} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle>Change {option} </DialogTitle>

                {(option==="password")?(
                        <ChangePasswordForm passwordInput={'*****'} sendDataToParent={handleDataFromChild} />

                    ):(
                        <Typography sx={{p:5}}>
                            Please contact us info@gitkits.com
                        </Typography>
                    ) }
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );
}
