import {CategoryType} from "../types/CategoryType";
import {Category} from "../domain/Category";
import {Kit} from "../domain/Kit";
import {Author} from "../domain/Author";
import axios from 'axios';
import {Article} from "../domain/Article";
import {ArticleType} from "../types/ArticleType";
import {KitRequestReduced} from "../types/KitRequestReduced";
export class ArticleApi {
    async getArticle (offset: number, limit : number ,order : string ): Promise<Array <Article>> {
        const articles =  await axios.get(process.env.REACT_APP_GITKITS+"/article?per_page="+limit+"&order="+order+"&page="+offset)
        return articles.data.map( (article: any) => {
            return this.mapArticle(article)
        })
    }
    async getKitByName (name: string ,offset: number, limit: number ): Promise<Array <Article>> {
        const articleReduced = await axios.get(process.env.REACT_APP_GITKITS+'/search?search='+name+'&subtype=article')
        const that = this
        const articlePromises = articleReduced.data.map( function(article: KitRequestReduced)  {
            return that.getArticleByID(article.id)
        })
        return await (Promise.all(articlePromises))
    }
    async getCategories ( ): Promise<Array <Category>> {
        const categories =  await axios.get(process.env.REACT_APP_GITKITS+'/categories?sad=ds')
        return categories.data.map( (category: any) => {
            return this.mapCategory(category)
        })
    }

    async getArticleByID (id: number): Promise<Article> {
        const post =  await axios.get(process.env.REACT_APP_GITKITS+'/article/'+id)
        return this.mapArticle(post.data)
    }

    async getAuthor (id: number): Promise<Author> {
        const author =  await axios.get(process.env.REACT_APP_GITKITS+'/users/'+id)
        return this.mapAuthor(author.data)
    }

    async getArticleByCategory (id: number ,offset: number, limit : number ,order : string ): Promise<Array <Article>> {
        const articles =  await axios.get(process.env.REACT_APP_GITKITS+'/article?per_page='+limit+'&categories='+id+"&order="+order+"&page="+offset)
        return articles.data.map( (article: ArticleType) => {
            return this.mapArticle(article)
        })
    }

    private mapArticle(article: ArticleType):Article {
        const articleObj = new Article();
        articleObj.setArticle(article)
        return  articleObj
    }

    private mapCategory(category: CategoryType): Category {
        const categoryObj = new Category();
        categoryObj.setCategory(category)
        return categoryObj
    }

    private mapAuthor(author: any): Author {
        const authorObj = new Author();
        authorObj.setAuthor(author)
        return authorObj
    }
}


