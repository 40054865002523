
import axios from 'axios';
import {AuthorRequest} from "../types/AuthorRequest";
import 'form-data';

export class UserApi {


    async login(user: string, password: string): Promise<string> {
        const body = {
            username: user,
            password: password
        }
        const response = await axios.post(process.env.REACT_APP_GITKITS_LOGIN + '/token', body)
        return response.data.token
    }
    async changePassword(id: number, token: string, password: string): Promise<string> {
        const options = {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body :{
                'password': password
            }
        }
        const response = await axios.post(process.env.REACT_APP_GITKITS+ '/users/' +id, options)
        return response.data
    }

    async sendCode(email :  string): Promise<any> {
        const data = JSON.stringify({
            "email": email
        });
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_GITKITS_JSON+ 'bdpwr/v1/reset-password',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };

        const response = await axios.request(config)
        return response.data
    }

    async changeWithCode(email: string, code: string, password: string): Promise<string> {

        const data = JSON.stringify({
            email: email,
            code: code,
            password: password
        });
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_GITKITS_JSON+ 'bdpwr/v1/set-password',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };
        const response = await axios.request(config)
        return response.data
    }

    async me(token: string): Promise<AuthorRequest> {
        const options = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(process.env.REACT_APP_GITKITS + '/users/me', options)
        return response.data
    }

    async createUser(user: string,email : string, password : string): Promise<number>{
        let data = JSON.stringify({
            "username": user,
            "password": password,
            "email": email
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_API_GITKITS+'/register',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };
        const result = await axios.request(config)
        return result.status
    }

}



