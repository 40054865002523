import * as React from 'react';
import {Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { Product } from '../../resources/domain/Product';


export const ProductKit = (props: {data: Product}) => (
    <Grid item xs={12} sm={6} md={4} key={props.data.id}>
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
            <CardMedia component='img' sx={{ height: '40vh', objectFit: 'contain' }}
                       image={props.data.image? props.data.image :  'https://source.unsplash.com/random'} alt='random'/>
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant='h5' component='h2'>
                    {props.data.name}
                </Typography>
                <Typography variant='body1'>
                    {props.data.description}
                </Typography>
            </CardContent>
            <CardActions sx={{justifyContent: 'left', p:2}}>
                <Button size='small'
                        onClick={() => window.open(props.data.url, '_blank')}
                        sx={{ color:'white', borderColor: '#4b30d1', px:3,
                        textTransform: 'none',backgroundColor: '#4b30d1', fontSize:'small', borderRadius: 0,
                        ':hover': {
                            backgroundColor: '#361db4',
                            color: 'white',
                            borderColor: '#4b30d1',
                        },
                }}>Buy Now</Button>

                <Typography variant='h6' sx={{px:1}}>
                    ${props.data.cost}
                </Typography>

            </CardActions>
        </Card>
    </Grid>
)
