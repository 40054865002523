export class KitProps {
    position : number
    cols : number
    rows : number
     constructor(position : number , cols = 1 , rows =2) {
        this.position  = position
         this.cols = cols
         this.rows = rows
     }

}