import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container, Modal } from '@mui/material';
import {UserService} from "../../resources/services/UserService";
import {useEffect, useState} from "react";
import {UserApi} from "../../resources/services/UserApi";
import ChangePass from "./ChangePass";
const theme = createTheme();

export default function ForgotPassword() {
    const [logged, setLogged] = useState(false)
    const [modal, setModal] = useState(false)
    const [message, setMessage] = useState('')
    const [emailValue , setEmailValue] = useState('');
    const [success, setSuccess] = useState(false)


    const handleEmail = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setEmailValue(event.target.value);
    };

    const handleOpen = () => setModal(true)
    const handleClose = () => setModal(false)

    const handleSubmit = () => {
        const userApi = new UserApi();
        userApi.sendCode(emailValue).then(res => {
            setMessage('Please check your inbox and don\'t close this window. \r\n You\'ll have 15 minutes to restore your password')
            setSuccess(true)
            handleOpen()
        }).catch(res => {
            console.log(res.response.data)
             setMessage(res.response.data.message)
             setSuccess(false)
             handleOpen()
         })
    }
    useEffect(() => {
            setLogged(UserService.isLogged())
        }
        ,[])
    if(logged) {
        window.location.href = '/home'
    }
    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid container component='main' sx={{ flexGrow: 1 }}>

                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{
                        backgroundColor: '#4b30d1',
                        flexWrap: 'wrap',
                        display:'flex',
                        justifyContent: 'left',
                        alignItems: 'center',

                    }}>
                        <Box
                            sx={{
                                padding: 5,
                                backgroundColor: 'white',
                                width:'70%',
                                margin:'0 auto'
                            }}
                        >
                            <Container sx={{display:'flex', alignItems:'center', justifyContent: 'center' }}>
                                <img src='/logo-black.svg' alt= 'logo'/>
                                <Typography component='h3' variant='h4'>
                                    git<b>Kits</b>
                                </Typography>
                            </Container>
                            <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <Typography >
                                    Please enter your email and we'll send you a link to recover your password
                                </Typography>
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='email'
                                    label='Email'
                                    name='email'
                                    autoComplete='email'
                                    autoFocus
                                    onChange={handleEmail}
                                />

                                <Button
                                    onClick={handleSubmit}
                                    fullWidth
                                    variant='contained'
                                    disabled = {!emailValue}
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Reset password
                                </Button>
                                <Link href='/signin' variant='body2'>
                                    Sign in
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            backgroundColor: 'white',
                            flexWrap: 'wrap',
                            display:'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{
                            alignItems:'center', justifyContent: 'center', width: '75%',
                            margin:'auto',
                            [theme.breakpoints.down('sm')]: {
                                display: 'none',
                            }
                        }}>
                            <Typography component='h4' variant='h4' sx={{width:'85%'}}>
                                <b>Join a community of people who know how to get things done.</b>
                            </Typography>
                            <Typography component='h6' variant='subtitle1' noWrap>
                                &nbsp;
                            </Typography>
                            <Typography component='h6' variant='subtitle1'>
                                Vote for your favorite kits, make reviews and more! Exclusive money making benefits to come for early joiners.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
            <Modal
                open={modal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{  position: 'absolute' as 'absolute',
                    top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    width: 500, bgcolor: 'background.paper', outline: 0, display: 'flex',
                    flexDirection:"column",
                    boxShadow: 24, p: 4}}>
                    <Typography variant="subtitle1" component="h2">
                        {message}
                    </Typography>
                    <Box  sx={{width:'100%'}}>
                        {success? (<ChangePass mail={emailValue} />): (<> </>) }
                    </Box>

                    <Button onClick={handleClose} sx={{position: 'absolute', bottom: '5%', left: '80%'}}>Close</Button>
                </Box>
            </Modal>
        </>
    );
}
