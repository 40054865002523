import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import {  useParams } from "react-router-dom";
import { Article } from '../../resources/domain/Article';
import HeaderArticlePage from './HeaderArticlePage';
import parse from 'html-react-parser'
import Disqus from "disqus-react"
import { env } from 'process';
import { ArticleService } from '../../resources/services/ArticleService';
import { Loading } from '../commons/Loading';
import { AboutUsFooter } from '../commons/AboutUsFooter';
import { ErrorPage } from '../commons/ErrorPage';


export default function BlogArticlePage() {
    const {articleName}  = useParams() as {articleName: string};
    const [article, setArticle] = useState<Article>()
    const [loading, setLoading] = useState(true)

    let disqusConfig = {
        url: '',
        identifier: '',
        title: ''
    }

    useEffect(() => {
        const urlParts = articleName.split('-')
        const idNumber : number = parseInt(urlParts[urlParts.length - 1])
        ArticleService.getArticle(idNumber)
            .then(setArticle)
            .catch((e) => { console.error(e) })
            .finally(() => { setLoading(false) })
    },[articleName])

    if (loading) {
        return <Loading color={'#4b30d1'} type={'spin'} />
    }
    if (!article) {
        return <ErrorPage message='Article not found' />
    }else{
        disqusConfig = {
            url: process.env.PUBLIC_URL+ '/articles/' + articleName,
            identifier: articleName,
            title: article.title
        }
    }

    return <Box sx={{ backgroundColor:'#f2f2f2', flexGrow: 1 }} >
        <HeaderArticlePage article={article} />
        <Box sx={{px:'10%', pb: 10, pt:5}}>
            {parse(article.content)}
            <Disqus.DiscussionEmbed
                shortname='Gitkits'
                config={disqusConfig}
            />
        </Box>
        <AboutUsFooter />
    </Box>
}