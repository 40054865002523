import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Kit } from '../../resources/domain/Kit';
import { useEffect, useState } from 'react';
import { KitService } from '../../resources/services/KitService';
import {  useParams } from "react-router-dom";
import { IconButton, ImageList, ImageListItem, ImageListItemBar,  Typography, useMediaQuery } from '@mui/material';
import { Loading } from '../commons/Loading';
import { FoundKitPreview } from './FoundKitPreview';
import GitterPreview from './GitterPreview';
import { AboutUsFooter } from '../commons/AboutUsFooter';


export default function SearchKitPage() {
    const mobile = useMediaQuery('(max-width:600px)');
    const {nombre}  = useParams() as {nombre: string};
    const [kits, setKit] = useState<Array<Kit>>([])
    const [relatedKits, setKitRelated] = useState<Array<Kit>>([])
    const [loading, setLoading] = useState(true)

    function srcset(image: string, size: number, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${
                size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    useEffect(() => {
        KitService.getKitsByName(nombre)
            .then(setKit)
            .catch((e) => { console.error(e) })
            .finally(() => {})
    }, [])

    useEffect(()=>{
        const categoriesIds = kits.map(kit => kit.category)
        const categoriesIdsFiltered = categoriesIds.filter((item, index) => categoriesIds.indexOf(item) === index);
        const categoriesPromises = categoriesIdsFiltered.map((id) => {
            return KitService.getKitsByCategory(id)
        })
        Promise.all(categoriesPromises).then(categoryKits => {
            const allKits : Array<Kit> = []
            categoryKits.forEach(kitList => {
                kitList.forEach(kit => {
                    allKits.push(kit)
                })

            })
            setKitRelated(allKits)
            setLoading(false)
        })
    }, [kits])

    function loadingElement() {
        return loading? <Loading color={'#4b30d1'} type={'spin'} /> : <></>
    }


    return <Box sx={{ backgroundColor:'#f2f2f2'}} >
        <Box sx={{ p: 8, flexGrow: 1}} >
        <Typography variant='h3' sx={{ textAlign: 'left',
                                        fontWeight:'bold',
                                        textTransform: 'capitalize',
                                        mb:8}}>
            {nombre}
        </Typography>
        <Grid container spacing={10} columns={16} >
            <Grid item xs={16} sm={16} md={8} >
                <Typography variant='h5' sx={{ textAlign: 'left', fontWeight:'bold', pb:5}}>
                    Best Matched Kits
                </Typography>

                { kits.length ? (
                     <ImageList
                            variant="quilted"
                            cols={1}
                            rowHeight= {120}
                            gap={25}
                        >
                            {kits.map( (kit)  => <FoundKitPreview data={kit} /> ) }
                        </ImageList>
                    )
                    : (
                        <Typography variant='h5' sx={{ textAlign: 'left'}}>
                            No kits found
                        </Typography>
                    )
                }

            </Grid>
            <Grid item xs={16} sm={16} md={8}>
                <Typography variant='h5' sx={{ textAlign: 'left', fontWeight:'bold', pb:5 }}>
                    Gitters
                </Typography>
                { kits.length ? (
                        <Grid container spacing={4}>
                          <GitterPreview data={kits} />
                        </Grid>
                    )
                    : <></>
                }
            </Grid>
        </Grid>
        <Typography variant='h5' sx={{ textAlign: 'left', fontWeight:'bold', py:5}}>
            Related Kits
        </Typography>
        <ImageList
            variant="quilted"
            cols={mobile ? 1 : 3}
            rowHeight= {260}
            gap={25}
        >
            {!relatedKits.length? loadingElement() : relatedKits
                .map((item, i) => (
                    <ImageListItem key={item.id}
                                   cols={ 1 }
                                   rows={ 1 } >
                        <img
                            {...srcset(item.image, 100, mobile ? 1 : (i === 4) ? 4 : 2, mobile ? 1 : (i === 4) ? 2 : 1)}
                            alt={item.name}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            sx={{
                                background:
                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                    'rgba(0,0,0,0.3) 40%, rgba(0,0,0,0) 100%)',
                                fontWeight: 'bold',
                                p: '8%'
                            }}
                            title={item.name}
                            position= 'top'
                        />
                        <ImageListItemBar
                            sx={{
                                background: 'rgba(0,0,0,0) 0%',
                            }}
                            position = 'bottom'
                            actionPosition= "left"
                            actionIcon={
                                <IconButton
                                    sx={{ background: 'rgba(255, 255, 255, 0.54)',
                                        color: 'black',
                                        borderRadius: 10,
                                        px: 5,
                                        m: 5,
                                        fontSize: 20,
                                        '&:hover': {
                                            background: 'rgba(255, 255, 255, 0.70)',
                                            fontWeight: 'bold',
                                        }
                                    }}
                                    href = {`/kit/${item.slug}-${item.id}`}
                                    aria-label = {`info about ${item.category}`}
                                >
                                    {item.categoryObj?.name}
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                ))}

        </ImageList>
        </Box>
        <AboutUsFooter />
    </Box>
}
