import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import {createTheme, IconButton, ImageListItemBar, ThemeProvider, useMediaQuery } from '@mui/material';
import {Kit} from "../../resources/domain/Kit";
import {KitProps} from "../../resources/domain/KitProps"

const theme = createTheme({
    components: {
        MuiImageListItemBar: {
            styleOverrides: {
                title: {
                    fontWeight: 'bold',
                    fontSize: 25,
                    lineHeight: 'normal',
                },
            },
        },
    },
});

function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

export default function FeaturedKitsBottom(props: {data: Array<Kit>}) {
        const featureProps : Array<KitProps> = [
        new KitProps(3,2,4),
        new KitProps(4),
        new KitProps(5),
        new KitProps(6),
        new KitProps(7),
        new KitProps(8),
        new KitProps(9),
        new KitProps(10,2,4),
        new KitProps(11),
        new KitProps(12)
    ]

    function getFeatureKitProps (kit : Kit): KitProps {
        const kitPropertiesFound = featureProps.filter(itemProps => itemProps.position === kit.featurePosition)
        return kitPropertiesFound.length? kitPropertiesFound[0] : (new KitProps(kit.featurePosition))
    }
    const featuresKits = props.data.filter( function (kit : Kit) {
        return (kit.featurePosition !== 0 && kit.featurePosition !== 1)
    })
    const mobile = useMediaQuery('(max-width:600px)');
    return (
        <ThemeProvider theme={theme}>
        <Box sx={{ margin: '0 auto'}}>
            <ImageList
            variant="quilted"
            cols={mobile ? 1 :4}
            rowHeight= {mobile ? 240 :120}
            gap={15}
            >
                {featuresKits
                    .sort((a,b) => a.featurePosition - b.featurePosition)
                    .map((item) => (
                        <ImageListItem key={item.featureImage}
                                       cols={
                                            mobile ? 1
                                                :
                                                getFeatureKitProps(item).cols
                                       }
                                       rows={
                                            mobile ? 1
                                                :
                                                getFeatureKitProps(item).rows
                                       }
                                       sx = {{cursor: 'pointer'}}
                                       onClick={() => window.location.href = `/kit/${item.slug}-${item.id}`}
                        >

                                <img
                                {...srcset(item.featureImage, 100 , getFeatureKitProps(item).rows, getFeatureKitProps(item).cols )}
                                alt={item.name}
                                loading="lazy"
                                />
                                <ImageListItemBar
                                    sx={{
                                        background:
                                            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                            'rgba(0,0,0,0.3) 40%, rgba(0,0,0,0) 100%)',
                                        fontWeight: 'bold',
                                        p: "2.5%"
                                    }}
                                    title={item.name}
                                    position= 'top'
                                />
                                <ImageListItemBar
                                    sx={{
                                        background: 'rgba(0,0,0,0) 0%',
                                        p: "12px 16px"
                                    }}
                                    position = 'bottom'
                                    actionPosition= "left"
                                    actionIcon={
                                        <IconButton
                                            sx={{ background: 'rgba(255, 255, 255, 0.54)',
                                                color: 'black',
                                                borderRadius: 10,
                                                p: 0,
                                                px: 5,
                                                m: "2.5%",
                                                fontSize: 20,
                                                '&:hover': {
                                                    background: 'rgba(255, 255, 255, 0.70)',
                                                    fontWeight: 'bold',
                                                }
                                            }}
                                            href={`/kit/${item.slug}-${item.id}`}
                                            aria-label={`info about ${item.categoryObj?.name}`}
                                        >
                                            {item.categoryObj?.name}
                                        </IconButton>
                                    }
                                />
                            </ImageListItem>
                    ))}
            </ImageList>
        </Box>
        </ThemeProvider>
    );
}

