import React from 'react'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/joy';
import { Toolbar } from '@mui/material';


export default function HeaderBlogPage() {

    return (
        <>
            <Paper
                sx={{
                    position: 'relative',
                    backgroundColor: 'grey.800',
                    color: '#fff',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: 'url(' + process.env.PUBLIC_URL + '/blog-bg.png)',
                    height: '30vh',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 0,

                }}
            >
                <Typography component='h1' variant='h2' color='white' sx ={{textAlign:'center', width:'100%'}} gutterBottom>
                    <b>GET YA KITS</b>
                </Typography>

            </Paper>
            {/*<Toolbar sx={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>*/}
            {/*    <Link style={{ textDecoration: 'none' }}>*/}
            {/*        <Typography variant='subtitle2' color='black' sx={{  mx:1,  }}>*/}
            {/*            Editorial Reviews*/}
            {/*        </Typography>*/}
            {/*    </Link>*/}
            {/*    <Link style={{ textDecoration: 'none' }}>*/}
            {/*        <Typography variant='subtitle2' color='black' sx={{  mx:1,  }}>*/}
            {/*            Insights*/}
            {/*        </Typography>*/}
            {/*    </Link>*/}
            {/*</Toolbar>*/}
        </>
    );
}