import {Category} from "../domain/Category";
import {Author} from "../domain/Author";
import {Article} from "../domain/Article";
import {ArticleApi} from "./ArticleApi";
import {Kit} from "../domain/Kit";
import {KitApi} from "./KitApi";

export class ArticleService {
    static async getArticles (offset = 1 , limit =30 ,order = "desc"):Promise<Array<Article>> {
        const articleApi = new ArticleApi()
        return (await articleApi.getArticle(offset,limit,order))
    }
    static async getArticleByCategory (category:number,offset = 1 ,limit = 30, order= "desc"): Promise<Array<Article>> {
        const categories = await this.getCategories()
        const articleApi = new ArticleApi()
        const articles = (await articleApi.getArticleByCategory(category,offset,limit,order))
        return articles.map((article) => {
            article.categoryObj = categories.find((category) => article.category = category.id)
            return article
        })

    }
    static async getArticleByName (name: string): Promise<Array<Article>> {
        const categories = await this.getCategories()
        const articleApi = new ArticleApi()
        const articles = (await articleApi.getKitByName(name,1,30))

        return articles.map((article) => {
            article.categoryObj = categories.find((category) => article.category = category.id)
            return article
        })

    }
    static async getCategories (): Promise<Array<Category>> {
        const articleApi = new ArticleApi()
        return (await articleApi.getCategories()).filter(category => category.name !== "-")

    }

    static async getArticle (articleId: number): Promise<Article> {
        const articleApi = new ArticleApi()
        return await articleApi.getArticleByID(articleId)
    }

    static async getAuthor (author: number): Promise<Author> { //: Promise<Array<Kit>>
        const articleApi = new ArticleApi()
        return await articleApi.getAuthor(author)
    }

}
