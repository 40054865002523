import React from 'react';
import Box from "@mui/joy/Box";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { Typography } from '@mui/material';


export const ErrorPage = (props: { message: string }) => (
    <Box sx={{ flexGrow: 1, width: '100%',  py:3, color:'#757575',
        display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize:30}}>
            {props.message}
            <SentimentDissatisfiedIcon sx={{ml:2, fontSize:40}}/>
    </Box>
);