import { Kit } from '../domain/Kit'
import { KitApi } from './KitApi'
import {Category} from "../domain/Category";
import {Author} from "../domain/Author";
import { LikeType } from '../types/LikeType';
import { UserService } from './UserService';

export class KitService {
    static async getKits ():Promise<Array<Kit>> {
        const kitApi = new KitApi()
        return (await kitApi.getKits(30,0)).map( (singleKit)=>{
            const kit = new Kit()
            kit.setKit(singleKit)
            return kit
        })
    }
    static async getKitsByCategory (category:number): Promise<Array<Kit>> {
        const categories = await this.getCategories()
        const kitApi = new KitApi()
        const kits = (await kitApi.getKitByCategory(category,30,0))

        return kits.map((kit) => {
            kit.categoryObj = categories.find((category) => kit.category = category.id)
            return kit
        })

    }
    static async getKitsByName (name: string): Promise<Array<Kit>> {
        const categories = await this.getCategories()
        const kitApi = new KitApi()
        const kits = (await kitApi.getKitByName(name,30,0))

        return kits.map((kit) => {
            kit.categoryObj = categories.find((category) => kit.category = category.id)
            return kit
        })

    }
    static async getCategories (): Promise<Array<Category>> {
        const kitApi = new KitApi()
        return (await kitApi.getCategories()).filter(cateogory => cateogory.name !== "-")

    }
    static async getKitsFeatureds (): Promise<Array<Kit>> {
        const kitApi = new KitApi()
        const kitsFeatured = await kitApi.getKitsFeatureds()
        const kits  = kitsFeatured.map( (singleKit)=>{
            const kit = new Kit()
            kit.setKit(singleKit)
            return kit
        })
        const categories = await this.getCategories()
        return kits.map((kit) => {
            kit.categoryObj = categories.find((category) => kit.category === category.id)

            return kit
        })
    }
    static async getKit (kitId: number): Promise<Kit> {
        const kitApi = new KitApi()
        const kit = await kitApi.getKitByID(kitId)
        const categories = await this.getCategories()
        kit.categoryObj = categories.find((category) => kit.category === category.id)
        return kit
    }
    static async getAuthor (author: number): Promise<Author> {
        const kitApi = new KitApi()
        return await kitApi.getAuthor(author)
    }

    static async getLikes (kitId: number): Promise<LikeType> {
        const kitApi = new KitApi()
        const userId = UserService.getUserLogged().id
        return await kitApi.getLikes(kitId, userId)
    }

    static async postLike (kitId: number) {
        const kitApi = new KitApi()
        const token = UserService.getToken()
        const userId = UserService.getUserLogged().id
        if(token)
            return await kitApi.postLike(kitId, token, userId)
        else
            return -1
    }

    static async deleteLike (kitId: number) {
        const kitApi = new KitApi()
        const token = UserService.getToken()
        const userId = UserService.getUserLogged().id
        if(token)
            return await kitApi.deleteLike(kitId, token, userId)
        else
            return -1
    }

}
