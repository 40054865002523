import React from 'react'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


export default function HeaderKitsPage() {

    return (
    <>
        <Paper
            sx={{
                position: 'relative',
                backgroundColor: 'grey.800',
                color: '#fff',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: 'url(' + process.env.PUBLIC_URL + '/kits-bg.png)',
                height: '30vh',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 0,

            }}
        >
                <Typography component='h1' variant='h2' color='white' sx ={{textAlign:'center', width:'100%'}} gutterBottom>
                    <b>Explore Kits</b>
                </Typography>

        </Paper>
    </>
    );
}