import { KitType } from '../types/KitType'
import {KitRequest} from "../types/KitRequest";
import {CategoryType} from "../types/CategoryType";
import {Category} from "../domain/Category";
import {Kit} from "../domain/Kit";
import {Author} from "../domain/Author";
import axios from 'axios';
import {KitRequestReduced} from "../types/KitRequestReduced";
import { LikeType } from '../types/LikeType';
export class KitApi {
    async getKits (offset: number, limit : number ): Promise<Array <KitType>> {
        const kits =  await axios.get(process.env.REACT_APP_GITKITS+'/kits/')
        return kits.data.map( (kit: any) => {
            return this.mapKit(kit)
        })
    }
    async getKitsFeatureds ( ): Promise<Array <KitType>> {
        const kits =  await axios.get(process.env.REACT_APP_GITKITS+'/kits?tag=featured&per_page=100')
        return kits.data.map( (kit: any) => {
            return this.mapKit(kit)
        })
    }
    async getCategories ( ): Promise<Array <Category>> {
        const categories =  await axios.get(process.env.REACT_APP_GITKITS+'/categories?sad=ds')
        return categories.data.map( (category: any) => {
            return this.mapCategory(category)
        })
    }
    async getKitByID (id: number): Promise<Kit> {

        const kit =  await axios.get(process.env.REACT_APP_GITKITS+'/kits/'+id)
        const kitMapped = this.mapKit(kit.data)
        return kitMapped
    }
    async getAuthor (id: number): Promise<Author> {
        const author =  await axios.get(process.env.REACT_APP_GITKITS+'/users/'+id)
        return this.mapAuthor(author.data)

    }
    async getKitByName (name: string ,offset: number, limit: number ): Promise<Array <Kit>> {
        const kitsReduced = await axios.get(process.env.REACT_APP_GITKITS+'/search?search='+name+'&subtype=kit')
        const that = this
        const kitsPromises = kitsReduced.data.map( function(kit: KitRequestReduced)  {
            return that.getKitByID(kit.id)
        })
        return  await (Promise.all(kitsPromises))
    }
    async getKitByCategory (id: number ,offset: number, limit: number ): Promise<Array <Kit>> {
        const kits =  await axios.get(process.env.REACT_APP_GITKITS+'/kits?per_page=100&categories='+id)
        return kits.data.map( (kit: KitRequest) => {
            return this.mapKit(kit)
        })
    }
    async getLikes (kitId: number, userId: number = -1) : Promise<LikeType> {
        let path = process.env.REACT_APP_API_GITKITS + '/like/' + kitId
        path += (userId>=0)? '/?user=' + userId : '';
        const response  = await axios.get(path)
        return response.data
    }

    async postLike (kitId: number, token: string = '', userId: number)  {
        const path = process.env.REACT_APP_API_GITKITS + '/like/' + kitId + '?user=' + userId
        const options = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const bodyParameters = {
            key: ''
        };

        const response = await axios.post(path, bodyParameters, options)
        return response.data
    }

    async deleteLike (kitId: number, token: string = '', userId: number)  {
        const path = process.env.REACT_APP_API_GITKITS + '/like/' + kitId + '?user=' + userId
        const options = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        const response = await axios.delete(path, options)
        return response.data
    }
    private mapKit(kit: KitRequest):Kit {
        const kitObj = new Kit();
        kitObj.setKitRequested(kit)
        return  kitObj
    }

    private mapCategory(categoy: CategoryType): Category {
        const categoryObj = new Category();
        categoryObj.setCategory(categoy)
        return categoryObj
    }
    private mapAuthor(author: any): Author {
        const authorObj = new Author();
        authorObj.setAuthor(author)
        return authorObj
    }
}


