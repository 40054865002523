import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import { useState } from 'react';

const Search = styled('div')(({ theme }) => ({
    display: 'flex',
    float: 'left',
    height: '100%',
    verticalAlign: 'center'
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: '#000000',
    '& .MuiInputBase-input': {
        paddingLeft: 1,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('md')]: {
            width: '40ch',
            '&:focus': {
                width: '40ch',
            },
        },
        [theme.breakpoints.up('md')]: {
            width: '40ch',
            '&:focus': {
                width: '40ch',
            },
        },
    },
}));

export default function SearchKits() {
    const [key, setKey] = useState({ value: '' });
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setKey({value: e.target.value});
    }
    return (
        <Box sx={{position: 'relative',
            borderRadius: 1,
            backgroundColor: 'white',
            margin:'auto',
            width: '60%',
            height: '60px',
            border: 1,
            borderColor: '#4b30d1',}}>
            <Search>
                <IconButton href = {'/search/' + key?.value} sx={{
                    ml: 1,
                    "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent"
                    }}}>
                    <SearchIcon sx={{color:'#4b30d1'}} />
                </IconButton>
                <StyledInputBase
                    placeholder='Search for kits or products by id or name'
                    inputProps={{ 'aria-label': 'search' }}
                    value={key?.value}
                    onChange={handleChange}
                    onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                            window.location.href = '/search/'+key?.value ;
                        }
                    }}
                />
            </Search>

        </Box>
    );
}