import * as React from 'react';
import {Button, ButtonGroup, Card, CardActions, CardContent, CardMedia, Grid, Link, Typography } from '@mui/material';
import { Kit } from '../../resources/domain/Kit';


export const SingleKitPreview = (props: {data: Kit}) => (
    <Grid item xs={12} sm={6} md={4}>
        <Link href={'/kit/'+props.data.slug+"-"+props.data.id} sx={{textDecoration: 'none'}}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
                <CardMedia component='img'
                           image={props.data.image} alt='random'/>
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant='h5' component='h2' >
                        {props.data.name}
                    </Typography>
                    <Typography variant='body1'>
                        {props.data.description}
                    </Typography>
                </CardContent>
                <CardActions>
                    <ButtonGroup variant='contained' aria-label='buy button' sx={{m:2}}>
                        <Button size='small' href={"kit/"+props.data.slug+"-"+props.data.id} sx={{ color:'white', borderColor: '#4b30d1',
                            textTransform: 'none',backgroundColor: '#4b30d1', fontSize:'small', borderRadius: 0,
                            ':hover': {
                                backgroundColor: '#361db4',
                                color: 'white',
                                borderColor: '#4b30d1',
                            },
                        }}>See more</Button>
                    </ButtonGroup>
                </CardActions>
            </Card>
        </Link>
    </Grid>
)
