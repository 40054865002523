import { Button } from '@mui/material';
import * as React from 'react';
import {useEffect, useState } from 'react';
import { UserService } from '../../resources/services/UserService';


export default function LogInHeader() {
    const [logged, setLogged] = useState(false)

    useEffect(() => {
        setLogged(UserService.isLogged())
    },[])

    return (
        <>
            {logged ? <>
                <Button href='/profile' variant='outlined' sx={{ my: 1, mx: 1.5, color: 'white',
                    textTransform: 'none',borderColor:'white',
                    ':hover': {
                        bgcolor: 'white',
                        color: '#4b30d1',
                        borderColor:'#4b30d1',
                    },}}>
                    Profile
                </Button>
                <Button href='/home' variant='outlined'
                        onClick={() => UserService.logout()}
                        sx={{ my: 1, mx: 1.5,color:'#4b30d1',
                            textTransform: 'none',backgroundColor: 'white',
                            borderColor: '#4b30d1',
                            ':hover': {
                                bgcolor: '#4b30d1',
                                color: 'white',
                                borderColor:'white'
                            },
                        }}>
                    Log Out
                </Button>
                </>:
                <>
                    <Button href='/signin' variant='outlined' sx={{ my: 1, mx: 1.5, color: 'white',
                        textTransform: 'none',borderColor:'white',
                        ':hover': {
                            bgcolor: 'white',
                            color: '#4b30d1',
                            borderColor:'#4b30d1',
                        },}}>
                        Sign In
                    </Button>
                    <Button href='/signup' variant='outlined' sx={{ my: 1, mx: 1.5,color:'#4b30d1',
                        textTransform: 'none',backgroundColor: 'white',
                        borderColor: '#4b30d1',
                        ':hover': {
                            bgcolor: '#4b30d1',
                            color: 'white',
                            borderColor:'white'
                        },
                    }}>
                        Join
                    </Button>
                </>
            }
        </>
    )
}