import {Box, IconButton, Snackbar, Typography } from '@mui/material';
import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));


export default function AdminSettings() {
    const [amazon, setAmazon] = useState({ value: '' });
    const [bestBuy, setBestBuy] = useState({ value: '' });
    const [open, setOpen] = React.useState(false);
    const [message, setmessage] = React.useState<string>("");
    const handleCloseSnackBar = () => {
        setOpen(false);
    };
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackBar}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    //put default values w use effect
    function handleChangeAmazon(e: React.ChangeEvent<HTMLInputElement>) {
        setAmazon({value: e.target.value});
    }
    function handleChangeBestBuy(e: React.ChangeEvent<HTMLInputElement>) {
        setBestBuy({value: e.target.value});
    }

    return (
        <>
            <Typography component='h3' variant='h5' sx={{fontWeight:'bold', pt:1, pb:2}}>
                Admin Settings
            </Typography>
            <FormControl variant="standard" sx={{display: 'block'}}>
                <InputLabel shrink htmlFor="amazon-id">
                    Amazon ID
                </InputLabel>
                <BootstrapInput placeholder="Amazon Associates ID" id="amazon-id" defaultValue=''
                                disabled
                                value={amazon?.value}
                                onChange={handleChangeAmazon}
                                onKeyDown={(ev) => {
                                    if (ev.key === 'Enter') {
                                        //change amazon id
                                        setmessage('Amazon ID changed')
                                        setOpen(true)
                                    }
                                }}/>
            </FormControl>
            <FormControl variant="standard" sx={{display: 'block'}}>
                <InputLabel shrink htmlFor="best-buy-id" >
                    BestBuy ID
                </InputLabel>
                <BootstrapInput placeholder="BestBuy ID" id="best-buy-id" defaultValue=''
                                disabled
                                value={bestBuy?.value}
                                onChange={handleChangeBestBuy}
                                onKeyDown={(ev) => {
                                    if (ev.key === 'Enter') {
                                        //change best buy id
                                        setmessage('Best Buy ID changed')
                                        setOpen(true)
                                    }
                                }}/>
            </FormControl>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                message={message}
                onClose={handleCloseSnackBar}
                action={action}
            />
        </>
    );
}