import {Box, Link, List, ListItem, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react'
import  EmailSubmit  from './EmailSubmit';

export const AboutUsFooter = () => (
        <Grid container sx={{backgroundColor:'white'}}>
            <Grid xs={12} sm={12} md={4} sx={{p:5}} >
                <Typography variant='h6' sx={{}}>
                    ABOUT US
                </Typography>
                <Typography variant='subtitle1' sx={{py:2}}>
                    GitKits was started as an aim to make it easier for people to handle life's major events and quickly grew to include much more.
                </Typography>
                <Typography variant='subtitle1' sx={{}}>
                    Disclaimer: This is an affiliate website. All external links to purchase products or services are affiliate URLs. Gitkits earns a commission from purcahses made via traffic from this website to these connected sites.
                </Typography>
            </Grid>
            <Grid xs={12} sm={5} md={3} sx={{p:5}}>
                <Typography variant='h6' sx={{px:2}}>
                    EXPLORE
                </Typography>
                <List >
                    <ListItem >
                        <Link underline='hover' color='inherit' href='/kits' >Kit Collections</Link>
                    </ListItem>
                    <ListItem >
                        <Link underline='hover' color='inherit' href='/blog'>Blog</Link>
                    </ListItem>
                    <ListItem >
                        <Link underline='hover' color='inherit' href={`mailto:info@gitkits.com`} >Contact</Link>
                    </ListItem>
                    <ListItem >
                        <Link underline='hover' color='inherit' href={`mailto:info@gitkits.com`} >Apply to be a Gitter</Link>
                    </ListItem>
                </List>
            </Grid>
            {/*<Grid xs={12} sm={6} md={4} >*/}
            {/*    <Box sx={{p:5}}>*/}
            {/*        <Typography variant='h6'*/}
            {/*                    sx={{pb:2}}*/}
            {/*        >*/}
            {/*            JOIN OUR NEWSLETTER*/}
            {/*        </Typography>*/}
            {/*        <EmailSubmit />*/}
            {/*    </Box>*/}
            {/*</Grid>*/}
        </Grid>
)
