import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

type Props = {
    sortKeyValue?: string;
    onSortKeyChange?: (newType: string) => void;
};
export default function SortArticle({sortKeyValue,onSortKeyChange}: Props) {

    const handleSortKeyChange = (event: SelectChangeEvent) => {
        onSortKeyChange?.(event.target.value);
    }

    return (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="sort-article">Sort by</InputLabel>
            <Select
                labelId="sort-article-label"
                id="sort-article"
                value={sortKeyValue}
                onChange={handleSortKeyChange}
                label="Sort by"
            >
                <MenuItem value={'desc'}>Newest</MenuItem>
                <MenuItem value={'asc'}>Oldest</MenuItem>
            </Select>
        </FormControl>
    );
}