import {CategoryType} from "../types/CategoryType";

export class Category {
    id: number
    count: number
    description: string
    name: string
    slug: string

    constructor() {
        this.id = 0
        this.name = ""
        this.description = ""
        this.count = 0
        this.name = ""
        this.slug = ""
    }
    setCategory (category: CategoryType) {
        this.id = category.id
        this.name = category.name
        this.description = category.description
        this.count = category.count
        this.name = category.name
        this.slug = category.slug
    }
}
