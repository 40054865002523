import React, {useEffect, useState} from 'react'
import { Kit } from '../../resources/domain/Kit'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import {Avatar, Breadcrumbs, Button, ButtonGroup, Container, Tooltip} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import {KitService} from "../../resources/services/KitService";
import {Author} from "../../resources/domain/Author";
import {Modal} from "@mui/joy";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon, FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import { LikeType } from '../../resources/types/LikeType';
import { UserService } from '../../resources/services/UserService';
import { SocialIcon } from 'react-social-icons'

export default function HeaderKitPage(props: {kit: Kit}) {

    const [author, setAuthor] = useState<Author>(new Author())
    const [modal, setModal] = useState(false)
    const [total, setTotal] = useState(0)
    const [likes, setLikes] = useState<LikeType>()
    const [liked, setLiked] = useState(false)
    const [modalError, setModalError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")

    const logged = UserService.isLogged()
    const handleOpen = () => setModal(true)
    const handleClose = () => setModal(false)
    const handleOpenError = (msg :string) => {
        setErrorMsg(msg)
        setModalError(true)
    }
    const handleCloseError = () => setModalError(false)
    const shareUrl: string = window.location + ''
    const title: string = props.kit.name

    useEffect(() => {
        let total = 0 ;
        props.kit.products.forEach( function (product ) {total += product.cost ? parseInt(product.cost+"") : 0 })
        setTotal(total)
        KitService.getAuthor(props.kit.author)
            .then(setAuthor)
            .catch((e) => { console.error(e) })
    }, [props.kit])

    useEffect(() => {
        KitService.getLikes(props.kit.id)
            .then(likes => {
                setLikes(likes)
                setLiked(likes.liked)
            })
            .catch((e) => { console.error(e) })
    } , [author, liked])

    const handleLike = () => {
        if (logged && !liked) {
            KitService.postLike(props.kit.id)
                .then()
                .catch((e) => { setLiked(false); handleOpenError(e.message) })
                .finally(() => { setLiked(true) })
        } else if (logged && liked) {
            KitService.deleteLike(props.kit.id)
                .then()
                .catch((e) => { setLiked(true); handleOpenError("") })
                .finally(() => { setLiked(false) })

        }
    }
    return (
        <>
            <Breadcrumbs separator="›" aria-label="breadcrumbs" sx={{p:1, pl:3}}>
                <Link
                    underline="hover"
                    color="black"
                    href="/kits"
                    fontSize="inherit"
                >
                    Kits
                </Link>
                <Link
                    underline="hover"
                    color="black"
                    href="/kits"
                    fontSize="inherit"
                >
                    {props.kit.categoryObj?.name}
                </Link>
                <Typography fontSize="inherit" sx={{ display: 'flex', alignItems: 'center' }}>
                    Kit#{props.kit.id}
                </Typography>
            </Breadcrumbs>

            <Paper
                sx={{
                    position: 'relative',
                    backgroundColor: 'grey.800',
                    color: '#fff',
                    mb: 4,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: 'url(' + props.kit.image + ')',
                }}
            >

                {<img style={{display: 'none'}} src={props.kit.image}  alt={props.kit.name}/>}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,.3)',
                    }}
                />

                <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        width: '100%',
                        position: 'relative',
                        px: {xs: 3, md: 12},
                        py: {xs: 2, md: 7},
                        //pr: {md: 0},
                    }}
                >
                    <Typography component='h1' variant='h3' color='white' sx={{px: 0, textAlign: 'center', fontSize:{xs: '2.5rem', md: '5vw'}}}
                                gutterBottom>
                        <b>{props.kit.name}</b>
                    </Typography>
                    <Typography variant='h5' color='white' sx={{px: 0, textAlign: 'center', fontSize:{xs: '1.5rem', md: '2vw'}}} paragraph>
                        {props.kit.description}
                    </Typography>
                    <Container sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Tooltip title={(!logged)?"You must be logged in to like":''}>
                                <span>
                        <ButtonGroup variant='outlined' aria-label='outlined primary button group' sx={{m: 2}}>

                                    <Button sx={{
                                        color: '#000', backgroundColor: '#34f68e',
                                        textTransform: 'none', borderColor: 'white',
                                        ':hover': {backgroundColor: '#87eab2', borderColor: 'white',},
                                        ':disabled': {borderColor: 'white'}
                                    }}
                                            disabled = { !logged  }
                                            onClick = {handleLike}

                                    >
                                        <ThumbUpIcon sx={{mx: 1, my: 0.5}}/>
                                        {(!likes?.liked )?'Upvote': 'Downvote'}
                                    </Button>

                            <Button sx={{
                                color: '#000', backgroundColor: '#34f68e',
                                textTransform: 'none', borderColor: 'white',
                                ':disabled': {backgroundColor: '#34f68e', borderColor: 'white', color: '#000',},
                            }}
                                    disabled
                            >{likes?.likes}</Button>

                        </ButtonGroup>
                    </span>
                        </Tooltip>
                        <Button onClick={handleOpen}
                                variant='outlined' sx={{
                            p: 1, textTransform: 'none', backgroundColor: 'white',
                            borderColor: 'white', color: '#4b30d1',
                            ':hover': {backgroundColor: '#efefef'}
                        }}>Share</Button>
                    </Container>
                    Total ${total}
                    <Container sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Avatar alt={author.name} src='/avatar-default.png'/>
                        <Link variant='subtitle1' href='#' sx={{px: 1, color: 'white'}}>
                            Created by {author.name}
                        </Link>
                    </Container>
                </Box>
            </Paper>
            <Modal
                open={modal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{  position: 'absolute' as 'absolute',
                    top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    width: 400, bgcolor: 'background.paper', outline: 0, display: 'flex',
                    boxShadow: 24, p: 4, justifyContent: 'space-between'}}>
                    <FacebookShareButton
                        url={shareUrl}
                        quote={title}
                    >
                        <SocialIcon network="facebook" style={{ height: 32, width: 32 }}/>
                    </FacebookShareButton>
                    <FacebookMessengerShareButton
                        url={shareUrl}
                        appId="521270401588372"
                        style={{ marginTop: '8px' }}
                    >
                        <FacebookMessengerIcon size={32} round />
                    </FacebookMessengerShareButton>
                    <TwitterShareButton
                        url={shareUrl}
                        title={title}
                    >
                        <SocialIcon network="x" style={{ height: 32, width: 32 }}/>
                    </TwitterShareButton>
                    <WhatsappShareButton
                        url={shareUrl}
                        title={title}
                        separator=":: "
                    >
                        <SocialIcon network="whatsapp" style={{ height: 32, width: 32 }}/>

                    </WhatsappShareButton>
                    <LinkedinShareButton url={shareUrl} >
                        <SocialIcon network="linkedin" style={{ height: 32, width: 32 }}/>
                    </LinkedinShareButton>
                    <EmailShareButton
                        url={shareUrl}
                        subject={title}
                        body="body"
                    >
                        <SocialIcon network="email" style={{ height: 32, width: 32 }}/>

                    </EmailShareButton>
                </Box>
            </Modal>
            <Modal
                open={modalError}
                onClose={handleCloseError}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{  position: 'absolute' as 'absolute',
                    top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    width: 400, bgcolor: 'background.paper', outline: 0, display: 'flex',
                    boxShadow: 24, p: 4, justifyContent: 'space-between'}}>
                    <Typography variant="subtitle1" component="h2">
                        {(errorMsg)? errorMsg: 'An error has occurred, please try again later or contact us.'}

                    </Typography>
                    <Button onClick={handleCloseError}>Close</Button>
                </Box>
            </Modal>
        </>
    )
}
