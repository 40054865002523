import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Category } from '../../resources/domain/Category';
import { useEffect, useState } from 'react';
import { ArticleService } from '../../resources/services/ArticleService';
import FormLabel from "@mui/joy/FormLabel";
import Radio, { radioClasses } from '@mui/joy/Radio';
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";

type Props = {
    categoryKeyValue?: string;
    onCategoryKeyChange?: (newType: string) => void;
};
export default function CategoriesBlog({categoryKeyValue,onCategoryKeyChange}: Props) {
    const [categories, setCategories] = useState<Array<Category>>([])
    useEffect(() => {
        ArticleService.getCategories()
            .then(setCategories)
            .catch((e) => { console.error(e) })
    }, [])
    const handleCategoryKeyChange = (event: SelectChangeEvent) => {
        onCategoryKeyChange?.(event.target.value);
    }

    return (
        <FormControl>
            <FormLabel
                sx={{
                    mb: 1,
                    overflow: 'auto',
                    fontWeight: "xl",
                    textTransform: "none",
                    fontSize: "xs",
                    letterSpacing: "0.15rem"
                }}
            >
            </FormLabel>
            <RadioGroup
                aria-labelledby="storage-label"
                value={categoryKeyValue}
                onChange = {handleCategoryKeyChange}
                sx={{ gap: 2, mb: 1, flexWrap: 'wrap', flexDirection: 'row' }}

            >
                <Sheet
                    key={"default"}
                    sx={{
                        px: 2,
                        py:1,
                        borderRadius: 20,
                        boxShadow: "sm",
                        textAlign: 'center',
                        m: 1,
                        "--joy-focus-outlineOffset": "4px",
                        "--joy-palette-focusVisible": (theme) =>
                            theme.vars.palette.neutral.outlinedBorder,
                        [`& .${radioClasses.checked}`]: {
                            [`& .${radioClasses.label}`]: {
                                fontWeight: "lg",
                                color: "white"
                            },
                            [`& .${radioClasses.action}`]: {
                                "--variant-borderWidth": "2px",
                                borderColor: "#1588ec",
                                backgroundColor: "#1588ec"
                            }
                        },
                        [`& .${radioClasses.action}.${radioClasses.focusVisible}`]: {
                            outlineWidth: "2px"
                        }
                    }}
                >
                    <Radio
                        label={"All"}
                        overlay
                        disableIcon
                        value={"default"}
                        slotProps = {{
                            label: () => ({
                                sx: {
                                    fontWeight: "lg",
                                    fontSize: "md",
                                    color: '#1588ec'
                                }
                            }),
                            action: () => ({
                                sx: () => ({
                                    borderColor: "#1588ec"
                                })
                            })
                        }}
                    />
                </Sheet>
                {categories.map((value) => (
                    <Sheet
                        key={value.id}
                        sx={{
                            px: 2,
                            py:1,
                            borderRadius: 20,
                            boxShadow: "sm",
                            textAlign: 'center',
                            m: 1,
                            "--joy-focus-outlineOffset": "4px",
                            "--joy-palette-focusVisible": (theme) =>
                                theme.vars.palette.neutral.outlinedBorder,
                            [`& .${radioClasses.checked}`]: {
                                [`& .${radioClasses.label}`]: {
                                    fontWeight: "lg",
                                    color: "white"
                                },
                                [`& .${radioClasses.action}`]: {
                                    "--variant-borderWidth": "2px",
                                    borderColor: "#1588ec",
                                    backgroundColor: "#1588ec"
                                }
                            },
                            [`& .${radioClasses.action}.${radioClasses.focusVisible}`]: {
                                outlineWidth: "2px"
                            }
                        }}
                    >
                        <Radio
                            label={value.name}
                            overlay
                            disableIcon
                            value={value.id}
                            slotProps = {{
                                label: () => ({
                                    sx: {
                                        fontWeight: "lg",
                                        fontSize: "md",
                                        color: '#1588ec'
                                    }
                                }),
                                action: () => ({
                                    sx: () => ({
                                        borderColor: "#1588ec"
                                    })
                                })
                            }}
                        />
                    </Sheet>
                ))}
            </RadioGroup>
        </FormControl>
    );
}