import { KitType } from '../types/KitType'
import { Product } from './Product'
import {KitRequest} from "../types/KitRequest";
import {Category} from "./Category";

export class Kit {
    id: number
    name: string
    slug : string
    description: string 
    category: number
    categoryObj : Category | undefined
    products: Product[] = []
    cost: number 
    likes: number 
    author: number
    url: string
    feature : boolean
    featureImage : string
    featurePosition : number
    image : string
    
    constructor(id = -1, name = '', description = '', category = 0,
                cost = 0, likes = 0, author = 0, url = '',image = '') {
        this.id = id
        this.name = name
        this.description = description
        this.category = category
        this.cost = cost
        this.likes = likes
        this.author = author
        this.url = url
        this.image = image
        this.feature = false
        this.featureImage = ''
        this.slug = ''
        this.featurePosition =-1
    }
    setKitRequested(kit: KitRequest){
        this.id = kit.id
        this.name = kit.acf.name
        this.slug = kit.slug
        this.description = kit.acf.description
        this.category = kit.categories[0]
        this.likes = 0
        this.author = kit.author
        this.image = kit.acf.kit_image
        this.feature = kit.acf.feature_image.length > 0
        if(this.feature){
            this.featureImage = kit.acf.feature_image
            this.featurePosition = parseInt(kit.acf.feature_position)
        }
        this.products = []
        for(let i = 0 ; i < 6; i++){
            // @ts-ignore
            const url = kit.acf["product"+i]
            // @ts-ignore
            const name = kit.acf["product"+i+"Name"]
            // @ts-ignore
            const price = kit.acf["product"+i+"Price"]
            // @ts-ignore
            const description = kit.acf["product"+i+"Description"]
            // @ts-ignore
            const imageObj : any = kit.acf["product"+i+"Image"]
            const image = imageObj && imageObj.url ?  imageObj.url : ""
            const product = new Product(
                name? name : "p name",
                1,
                description ? description : "p descript",
                price ? parseInt(price) : 0,
                4,
                url,
                image
            )
            this.products.push(product)
        }
    }
    setKit (kit: KitType) {
        this.id = kit.id
        this.name = kit.name
        this.description = kit.description
        this.category = kit.category
        this.cost = kit.cost
        this.slug = kit.slug
        this.likes = kit.likes
        this.author = kit.author
        this.image = kit.image
        this.feature = kit.featureImage.length > 0
        if(this.feature){
            this.featureImage = kit.featureImage
            this.featurePosition = parseInt(kit.featurePosition)
        }
        this.products = kit.products.map(e => {
            const product = new Product();
            product.setProduct(e);
            return product;
        })
    }
}
