import {AuthorRequest} from "../types/AuthorRequest";
import {AuthorType} from "../types/AuthorType";
export class Author {
    id: number
    name: string
    profession : string
    url: string
    description : string
    constructor() {
        this.id = 0
        this.name = ""
        this.profession = ""
        this.url = ""
        this.description = ""
    }
    setAuthor(author: AuthorRequest){
        this.id = author.id
        this.name = author.name
        this.url = author.url
        this.description = author.description
        this.profession = author.acf.profession
    }
    setAuthorByType(author: AuthorType){
        this.id = author.id
        this.name = author.name
        this.url = author.url
        this.profession = author.profession
    }
}